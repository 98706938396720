import { useLocation, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import VerifyOTP from "../../shared/common/VerifyOTP";
import { useOtp } from "../../store/context/otp-context";
import { useDispatch } from "react-redux";
import {
  setUserLogin,
  setAdminLogin,
} from "../../store/redux/actions/userActions";
import { useNotification } from "../../store/context/notification-context";
import { useContext, useEffect, useState } from "react";
import { useApiRequests } from "../../utils/api/https/executor";
import { DashboardContext } from "../../store/context/DashboardContext";
import { useTenant } from "../../store/context/tenant-context";
import { SelfUserClassificationEnum } from "../../shared/constants/constants";
import { navigateToAuth } from "../../utils/helper/redirect";
import { usePermissions } from "../../store/context/permission-context";

const OTPPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    otp,
    resetOTP,
    setOtp,
    setOtpError,
    setIsResendDisabled,
    setSeconds,
  } = useOtp();
  const triggerNotification = useNotification();
  const tenant = useTenant();

  const { state } = location;
  const loginContext = state ? JSON.parse(state.loginContext) : null;
  const { userId, requestId, emailAddress } = loginContext || {};
  const { fetchPolicies, setStarted } = useContext(DashboardContext);
  const isAdmin = location.pathname.startsWith("/admin");

  useEffect(() => {
    if (!loginContext) {
      navigateToAuth({ isAdmin: false });
    }
  }, [loginContext, navigate]);
  const { resendOTP, validateOTP } = useApiRequests();
  const { updatePermissions } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);

  const handleNavigate = () => {
    navigate(-1);
  };

  const handleResend = async () => {
    setOtpError(null);
    setOtp("");
    setSeconds(59);
    setIsResendDisabled(true);

    try {
      const dataToPost = { userId, requestId };
      const response = await resendOTP(dataToPost);
      const result = response?.outData;

      if (result?.isSuccessful) {
        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message,
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      // triggerNotification({
      //   type: "error",
      //   message: "Error",
      //   description: error?.message || "An error occurred.",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      // Step 1: Verify credentials
      const verificationResponse = await validateOTP({
        userId,
        requestId,
        otp,
      });
      const verificationData = verificationResponse?.outData;

      if (verificationData?.isSuccessful) {
        // Step 2: Dispatch login action
        const dataToPost = {
          userId: emailAddress,
          otpVerificationRequestId: requestId,
        };
        const response = await (isAdmin
          ? dispatch(setAdminLogin(dataToPost))
          : dispatch(setUserLogin(dataToPost)));
        const result = response?.outData;

        if (result?.isSuccessful) {
          setStarted(true);
          fetchPolicies();
          updatePermissions();

          result?.data?.userClassification ===
          SelfUserClassificationEnum.BACKOFFICE_ADMINISTRATORS.value
            ? navigate("/admin-dashboard")
            : navigate("/dashboard");
        } else {
          triggerNotification({
            type: "error",
            message: "Error",
            description: result?.message || "An error occurred during login.",
          });
        }
      } else {
        triggerNotification({
          type: "error",
          message: "Verification Failed",
          description:
            verificationData?.message || "The provided OTP is invalid.",
        });
      }
    } catch (error) {
      // triggerNotification({
      //   type: "error",
      //   message: "Login Failed",
      //   description:
      //     error?.message || "An error occurred. Please try again later",
      // });
    } finally {
      //resetOTP();
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4">
      <div className="mt-16 mb-3">
        <span>
          <button
            className={`focus:outline-none hover:text-[${tenant.theme.colorPrimary}]`}
          >
            <LeftOutlined className="mr-6" onClick={handleNavigate} />
          </button>
        </span>
        <span className="text-lg font-semibold text-left">Verify OTP</span>
      </div>

      <div className="text-center">
        <VerifyOTP
          isLoading={isLoading}
          formData={loginContext}
          handleSubmit={handleSubmit}
          handleResend={handleResend}
        />
      </div>
    </div>
  );
};

export default OTPPage;
