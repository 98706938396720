import Papa from "papaparse";

export const preventTextInput = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const preventNumericInput = (event) => {
  if (/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const validateBirthDate = (_, value) => {
  if (!value) {
    return Promise.resolve();
  }

  const today = new Date();
  const selectedDate = new Date(value);

  const minDate = new Date(
    today.getFullYear() - 70,
    today.getMonth(),
    today.getDate()
  );
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  if (selectedDate >= minDate && selectedDate <= maxDate) {
    return Promise.resolve();
  }

  if (selectedDate < maxDate) {
    return Promise.reject(new Error("Maximum required age is 70 years."));
  } else {
    return Promise.reject(new Error("Minimum required age is 18 years."));
  }
};

export const hexToRgba = (hex, opacity) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getFileBase64AndType = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error("No file provided"));
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      const fileType = file.type;

      resolve({ base64String, fileType });
    };

    reader.onerror = (error) => reject(error);
  });
};

export const downloadFile = (base64String, fileName, type) => {
  // Decode the Base64 string to a binary format
  const binaryString = window.atob(base64String);

  // Convert binary string to a typed array
  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from the typed array
  const blob = new Blob([byteArray], { type: type });

  // Create a temporary download link
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName || "download";

  // Trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up the link
  document.body.removeChild(link);
};

export const createCSVParser = ({
  mandatoryFields = [],
  onSuccess = () => {},
  onError = () => {},
  onMissingFields = () => {},
  stateSetters = {},
}) => {
  const parseCSV = (info) => {
    return new Promise((resolve, reject) => {
      const { status } = info.file;
      if (status !== "done") {
        return;
      }

      const file = info.file.originFileObj;
      const fileExtension = file.name.split(".").pop();
      const isCSV = ["csv"].includes(fileExtension.toLowerCase());

      if (!isCSV) {
        const error = new Error(
          `Only CSV files are allowed. Received: ${fileExtension.toLowerCase()}`
        );
        onError(error);
        reject(error);
        return;
      }

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          try {
            if (results.errors.length > 0) {
              throw new Error(`Parse error: ${results.errors[0].message}`);
            }

            const headers = Object.keys(results.data[0]);

            // Validate mandatory fields
            const missingFields = mandatoryFields.filter(
              (field) =>
                !headers
                  .map((h) => h.toLowerCase())
                  .includes(field.toLowerCase())
            );

            if (missingFields.length > 0) {
              const error = new Error(
                `Missing mandatory fields: ${missingFields.join(", ")}`
              );
              onMissingFields(missingFields);
              reject(error);
              return;
            }

            // Clean the data
            const cleanedData = results.data.map((row) =>
              Object.fromEntries(
                Object.entries(row).map(([key, value]) => [
                  key,
                  typeof value === "string"
                    ? value.replace(/"/g, "").trim()
                    : value,
                ])
              )
            );

            const parsedData = {
              headers,
              data: cleanedData,
              raw: results.data,
              meta: results.meta,
            };
            console.log("stateSetters? ? ? ? ", stateSetters);
            // Update state if setters are provided
            const { setTableData, setTableColumns, setDataToBeSubmitted } =
              stateSetters;

            if (setTableColumns) {
              setTableColumns(
                headers.map((header) => ({
                  title: header,
                  dataIndex: header,
                  key: header,
                  editable: true,
                }))
              );
            } else {
              console.log("not availabele???");
            }

            if (setTableData) {
              setTableData(
                cleanedData.map((row, index) => ({ ...row, key: index }))
              );
            }

            if (setDataToBeSubmitted) {
              setDataToBeSubmitted({
                mandates: cleanedData,
              });
            }

            onSuccess(parsedData);
            resolve(parsedData);
          } catch (error) {
            onError(error);
            reject(error);
          }
        },
        error: (error) => {
          onError(error);
          reject(error);
        },
      });
    });
  };

  return parseCSV;
};
