import axios from "axios";
import axiosInstance from "../../interceptors/axiosInstance";
import { getSessionData } from "../../interceptors/localStorageService";
import { getApiBaseUrl } from "../../../shared/config/tenantConfiguration";

const baseUrl = getApiBaseUrl();// "https://apitesting.borderlesspay.co.uk/Fintech.CBS.Backend";

// Function to make a post request
export const postRequest = async (command, data, language) => {
  let endpoint = "/api/bpm/cmd";
  if (language) {
    endpoint.concat("?lang=").concat(language);
  }
  const dataToSend = {
    cmd: command,
    data: JSON.stringify(data),
  };

  try {
    const response = await axios.post(`${baseUrl}${endpoint}`, dataToSend);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Function to make an authenticated post request with `language` passed as an argument
export const postAuthenticatedRequest = async (command, data, language) => {
  let endpoint = "/api/bpm/selfservice/cmd";
  if (language) {
    endpoint.concat("?lang=").concat(language);
  }
  const dataToSend = {
    cmd: command,
    data: JSON.stringify(data),
  };

  // var sessionData = getSessionData();
  try {
    const response = await axiosInstance.post(endpoint, dataToSend);
    return response.data;
  } catch (error) {
    // console.log("error", error);  
    // console.log("error.response", sessionData); 
    
    
    return Promise.reject(error);
  }
};

