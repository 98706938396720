import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";
import { downloadFileType } from "../../../shared/constants/constants";
import { downloadFile } from "../../../utils/helper/util";

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const triggerNotification = useNotification();
  const [allCustomers, setAllCustomers] = useState(null);
  const [isLoanDetailsLoading, setIsLoanDetailsLoading] = useState(false);
  const [isUserDetailsLoading, setIsUserDetailsLoading] = useState(false);
  const [isAllCustomerLoading, setIsAllCustomerLoading] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const [filter, setFilter] = useState({
    status: "",
    customerType: "",
    searchText: "",
    startDate: "",
    endDate: "",
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [customerDetails, setCustomerDetails] = useState(null);
  const [activeModal, setActiveModal] = useState(null);

  const openModal = (modalName) => {
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setActiveModal(null);
  };
  const getAllCustomers = useCallback(async () => {
    try {
      setIsAllCustomerLoading(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject({ ...filter, ...pagination });

      console.log("cleaned filter is", cleanedFilter);

      const response = await postAuthenticatedRequest(
        // "RefreshPlatformRolesCommand"
        "RetrieveSelfServiceCustomersQuery",
        cleanedFilter
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        console.log("result?.data from customers", result?.data);
        setAllCustomers(result);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsAllCustomerLoading(false);
    }
  }, [triggerNotification, filter, pagination]);

  // download customer report
  const downloadCustomersReport = useCallback(async () => {
    try {
      setIsDownloadingReport(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject({
        ...filter,
        ...pagination,
        isExport: true,
        fileExportName: "CustomerReport",
        exportFields: {
          displayName: "Display Name",
          userType: "User Type",
          branchName: "Branch",
          createdAt: "Reg. Date",
          emailAddress: "Email",
          mobileNumber: "Phone",
          userStatusDescription: "Status",
        },
      });

      const response = await postAuthenticatedRequest(
        "RetrieveSelfServiceCustomersQuery",
        cleanedFilter
      );

      const result = response?.outData;
      console.log("result from download customers", result);
      if (result?.isSuccessful) {
        downloadFile(result?.data, "Customer_Report", downloadFileType.EXCEL);

        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "Customer report downloaded",
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsDownloadingReport(false);
    }
  }, [triggerNotification, filter, pagination]);

  // API call function to get loan details by ID
  const getLoanQuoteDetails = useCallback(
    async (loanQuoteId) => {
      try {
        setIsLoanDetailsLoading(true);
        const response = await postAuthenticatedRequest(
          "GetLoanQuoteWithDetailsQuery",
          { loanQuoteId: Number(loanQuoteId) }
        );
        const result = response?.outData;
        if (result?.isSuccessful) {
          setCustomerDetails(result?.data);
        } else {
          triggerNotification({
            type: "error",
            message: "Error",
            description: result?.message,
          });
        }
      } catch (error) {
        triggerNotification({
          type: "error",
          message: "Error",
          description: error?.message || "Failed to fetch user data",
        });
      } finally {
        setIsLoanDetailsLoading(false);
      }
    },
    [triggerNotification]
  );

  // API call function to get user details by ID
  const getUserDetails = useCallback(
    async (userId) => {
      try {
        setIsUserDetailsLoading(true);
        const response = await postAuthenticatedRequest(
          "GetUserWithDetailsQuery",
          { userId }
        );

        const result = response?.outData;
        setIsUserDetailsLoading(false);
        if (result?.isSuccessful) {
          setCustomerDetails(result?.data);
        } else {
          triggerNotification({
            type: "error",
            message: "Error",
            description: result?.message,
          });
        }
      } catch (error) {
        triggerNotification({
          type: "error",
          message: "Error",
          description: error?.message || "Failed to fetch user data",
        });
      } finally {
        setIsUserDetailsLoading(false);
      }
    },
    [triggerNotification]
  );

  return (
    <CustomerContext.Provider
      value={{
        allCustomers,
        isAllCustomerLoading,
        isLoanDetailsLoading,
        isUserDetailsLoading,
        isDownloadingReport,
        getAllCustomers,
        downloadCustomersReport,
        getUserDetails,
        getLoanQuoteDetails,
        filter,
        setFilter,
        pagination,
        setPagination,
        customerDetails,
        activeModal,
        setActiveModal,
        openModal,
        closeModal,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  return useContext(CustomerContext);
};
