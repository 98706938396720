import { lazy } from "react";
import { CustomerProvider } from "../../store/context/admin/customer-context.js";
import withProvider from "../../store/context/withProvider.js";
import { LoanRequestProvider } from "../../store/context/admin/loan-request-context.js";
import { TransferProvider } from "../../store/context/admin/transfer-context.js";
import { TransactionProvider } from "../../store/context/admin/transaction-context.js";
// import { OrganisationProvider } from "../../store/context/admin/organisation-context.js";
import { ProductConfigurationProvider } from "../../store/context/admin/product-configuration-context.js";
import { LimitManagementProvider } from "../../pages/Requests/context/LimitManagementContext.js";
import { ProviderProvider } from "../../store/context/admin/provider-context.js";
import { AdminDashboardProvider } from "../../store/context/admin/dashboard-context.js";

export const ProtectedUserRoutes = [
  {
    path: "policies/*",
    element: lazy(() => import("../../components/Routes/PoliciesRoutes")),
  },
  {
    path: "policies/:policyId",
    element: withProvider(
      lazy(() => import("../../components/Policies/Policy")),
      [CustomerProvider]
    ),
  },

  {
    path: "/:quoteId",
    element: lazy(() => import("../../components/Dashboard/Quote")),
  },
  {
    path: "claims",
    element: lazy(() => import("../../components/Claims/Claims")),
  },

  {
    path: "airtime",
    element: lazy(() => import("../../pages/Airtime/AirtimeType")),
  },

  {
    path: "airtime-form",
    element: lazy(() => import("../../pages/Airtime/AirtimeForm")),
  },

  {
    path: "data-form",
    element: lazy(() => import("../../pages/Data/DataForm")),
  },

  {
    path: "data",
    element: lazy(() => import("../../pages/Data/DataType")),
  },

  {
    path: "bills-payment",
    element: lazy(() => import("../../pages/Bills/BillType")),
  }, //statement-request

  {
    path: "requests/statement-request",
    element: lazy(() => import("../../pages/Requests/StatementForm")),
  },
  {
    path: "requests/contact-update-request",
    element: lazy(() => import("../../pages/Requests/ContactForm")),
  },
  {
    path: "requests/account-reactivation-request",
    element: lazy(() =>
      import("../../pages/Requests/AccountReactivationForm.js")
    ),
  },
  {
    path: "requests/limit-management",
    element: withProvider(
      lazy(() => import("../../pages/Requests/LimitManagement.js")),
      [LimitManagementProvider]
    ),
  },
  {
    path: "requests",
    element: lazy(() => import("../../pages/Requests/RequestSelector")),
  }, //
  {
    path: "bills-form",
    element: lazy(() => import("../../pages/Bills/BillForm")),
  },

  {
    path: "transfer-form",
    element: lazy(() => import("../../pages/Transfers/TransferForm")),
  },

  {
    path: "transfer-type",
    element: lazy(() => import("../../pages/Transfers/TransferType")),
  },
  {
    path: "all-claims",
    element: lazy(() => import("../../components/Claims/AllClaims")),
  },
  {
    path: "claim-policy",
    element: lazy(() => import("../../components/Claims/ClaimPolicy")),
  },
  {
    path: "list-claims",
    element: lazy(() => import("../../components/Claims/ListClaims")),
  },
  {
    path: "form-claims",
    element: lazy(() => import("../../components/Claims/ClaimForm")),
  },
  {
    path: "extra-product-info",
    element: lazy(() =>
      import("../../components/Extra Products Info/ExtraProductInfo.js")
    ),
  },
  {
    path: "payments",
    element: lazy(() => import("../../components/Payments/Payments")),
  },
  {
    path: "payment-success",
    element: lazy(() => import("../../components/Payments/PaymentSuccess")),
  },
  {
    path: "transaction/:transactionId",
    element: lazy(() => import("../../components/Payments/Transaction")),
  },

  {
    path: "pay/with-account",
    element: lazy(() =>
      import("../../components/Payment Methods/Pages/PayWithAccount.js")
    ),
  },
  {
    path: "pay/with-card",
    element: lazy(() =>
      import("../../components/Payment Methods/Pages/PayWithCard.js")
    ),
  },
  {
    path: "pay/with-mobile-money",
    element: lazy(() =>
      import("../../components/Payment Methods/Pages/PayWithMobileMoney.js")
    ),
  },
  {
    path: "pay/payment-otp",
    element: lazy(() =>
      import("../../components/Payment Methods/Pages/PaymentOtp.js")
    ),
  },

  {
    path: "pin/reset-otp",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/ResetPinOTP.js")
    ),
  },
  {
    path: "pin/reset-complete",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/ResetPin.js")
    ),
  },

  {
    path: "loan/types",
    element: lazy(() => import("../../pages/Loan/LoanType")),
  },
];

export const ProtectedAdminRoutes = [
  // Admin routes
  {
    path: "admin-dashboard",
    element: withProvider(
      lazy(() => import("../../pages/Admin/Dashboard/AdminDashboard.js")),
      [AdminDashboardProvider]
    ),
  },
  {
    path: "general-search",
    element: lazy(() =>
      import("../../pages/Admin/GeneralSearch/GeneralSearch.js")
    ),
  },
  {
    path: "customers",
    element: withProvider(
      lazy(() => import("../../pages/Admin/Customers/AdminCustomer.js")),
      [CustomerProvider]
    ),
  },

  {
    path: "/mandate/selector",
    element: lazy(() =>
      import("../../pages/Admin/Mandate/MandateSelector.jsx")
    ),
  },
  {
    path: "/mandate/view-mandate/:mandateReference",
    element: lazy(() => import("../../pages/Admin/Mandate/ViewMandate.jsx")),
  },

  {
    path: "/mandate/view-collection/:collectionId",
    element: lazy(() => import("../../pages/Admin/Mandate/ViewCollection.jsx")),
  },

  {
    path: "/mandate/view-batch/:id",
    element: lazy(() => import("../../pages/Admin/Mandate/ViewBatch.jsx")),
  },
  {
    path: "/mandate/collection-report",
    element:
      lazy(() =>
        import("../../pages/Admin/Mandate/AdminCollectionsReport.jsx")

      ),
  },
  {
    path: "/mandate/collection-report",
    element: lazy(() =>
      import("../../pages/Admin/Mandate/AdminCollectionsReport.jsx")
    ),
  },
  {
    path: "/mandate/upload-mandate",
    element: lazy(() => import("../../pages/Admin/Mandate/UploadDetails.jsx")),
  },
  {
    path: "/collection/initiate-batch-form",
    element: lazy(() =>
      import("../../pages/Admin/Mandate/AdminCollectionBatchForm.jsx")
    ),
  },

  {
    path: "/collection/reports",
    element: lazy(() =>
      import("../../pages/Admin/Mandate/AdminBatchReport.jsx")
    ),
  },
  {
    path: "/collection/initiate-single-form",
    element: lazy(() =>
      import("../../pages/Admin/Mandate/AdminCollectionSingleForm.jsx")
    ),
  },

  {
    path: "/mandate/reports",
    element: lazy(() =>
      import("../../pages/Admin/Mandate/AdminMandatesReport.jsx")
    ),
  },

  {
    path: "/mandate/batches/reports",
    element: lazy(() =>
      import("../../pages/Admin/Mandate/AdminMandatesBatchesReport.jsx")
    ),
  },

  {
    path: "/mandate/manual-form",
    element: lazy(() =>
      import("../../pages/Admin/Mandate/AdminMandatesForm.jsx")
    ),
  },

  {
    path: "/settings/user/view/:userId",
    element: lazy(() =>
      import("../../pages/Settings And More/Corporate Settings/ViewUser.jsx")
    ),
  },
  {
    path: "customers/:userId",
    element: withProvider(
      lazy(() => import("../../pages/Admin/Customers/AdminCustomerDetails.js")),
      [CustomerProvider]
    ),
  },
  {
    path: "loan-requests",
    element: withProvider(
      lazy(() => import("../../pages/Admin/LoanRequests/AdminLoanRequest.js")),
      [LoanRequestProvider, ProductConfigurationProvider]
    ),
  },
  {
    path: "transactions",
    element: withProvider(
      lazy(() => import("../../pages/Admin/Transactions/AdminTransaction.js")),
      [TransactionProvider]
    ),
  },
  {
    path: "transfers",
    element:
      lazy(() => import("../../pages/Admin/Transfers/AdminTransfer.js")),
  },
  {
    path: "general-requests/others",
    element: lazy(() =>
      import("../../pages/Admin/GeneralRequests/GeneralRequest.js")
    ),
  },

  {
    path: "general-requests-selector",
    element: lazy(() =>
      import("../../pages/Admin/GeneralRequests/RequestSelector.js")
    ),
  },

  {
    path: "general-requests/limit-requests",
    element: lazy(() =>
      import("../../pages/Admin/GeneralRequests/limits/LimitRequest.js")
    ),
  },
  {
    path: "general-requests/account-reactivations",
    element: lazy(() =>
      import("../../pages/Admin/GeneralRequests/AccountReactivationRequest.js")
    ),
  },
  {
    path: "general-requests/limit-management",
    element: lazy(() =>
      import("../../pages/Admin/GeneralRequests/limits/AdminLimitManagement.js")
    ),
  },
  {
    path: "general-requests/provider-management",
    element: withProvider(
      lazy(() =>
        import(
          "../../pages/Admin/GeneralRequests/providers/AdminProviderManagement.js"
        )
      ),
      [ProviderProvider]
    ),
  },
  {
    path: "organisations",
    element: lazy(() =>
      import("../../pages/Admin/Organisations/AdminOrganisation.js")
    ),
  },
  {
    path: "product-configuration",
    element: withProvider(
      lazy(() =>
        import(
          "../../pages/Admin/ProductConfiguration/AdminProductConfiguration.js"
        )
      ),
      [ProductConfigurationProvider]
    ),
  },
];

export const ProtectedSharedRoutes = [
  {
    path: "/settings",
    element: lazy(() =>
      import("../../pages/Settings And More/HandleAccountType")
    ),
  },
  {
    path: "/settings/security",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/PersonalSecurity")
    ),
  },
  {
    path: "/settings/security/change-password",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/ChangePassword")
    ),
  },
  {
    path: "/settings/security/change-pin",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/ChangePin")
    ),
  },
  {
    path: "/settings/security/setup-pin",
    element: lazy(() => import("../../components/Dashboard/pin/SetUpNewPin")),
  },
  {
    path: "/settings/security/edit-question",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/EditQuestion")
    ),
  },
  {
    path: "/settings/security/security-questions",
    element: lazy(() =>
      import(
        "../../pages/Settings And More/Personal Settings/SecurityQuestions"
      )
    ),
  },
  {
    path: "/settings/notifications",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/Notifications")
    ),
  },
  {
    path: "/settings/notifications",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/Notifications")
    ),
  },
  {
    path: "/settings/profile",
    element: lazy(() =>
      import("../../pages/Settings And More/Personal Settings/Profile")
    ),
  },
  {
    path: "/settings/security/otp-verification",
    element: lazy(() => import("../otp-verification/OtpVerification")),
  },
  {
    path: "/settings/all-users",
    element: lazy(() =>
      import("../../pages/Settings And More/Corporate Settings/AllUsers")
    ),
  },

  // todo: this should be on shared route
  {
    path: "/settings/manage-user/create",
    element: lazy(() =>
      import("../../pages/Settings And More/Corporate Settings/ManageUser")
    ),
  },

  // {
  //   path: "/validate-user",
  //   element: lazy(() =>
  //     import("../../pages/Settings And More/Corporate Settings/ValidateUser.js")
  //   ),
  // },
  // {
  //   path: "/validate-user/security-features",
  //   element: lazy(() =>
  //     import(
  //       "../../pages/Settings And More/Corporate Settings/SecurityFeatures.js"
  //     )
  //   ),
  // },
];
