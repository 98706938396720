import {
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Select,
  Upload,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import {
  MAX_VALID_ID_SIZE,
  MeansOfIdentificationEnum,
} from "../../../shared/constants/constants";
import { PhoneAreas } from "../../../utils/data/jsonData";
import { getFileBase64AndType } from "../../../utils/helper/util";

const { Option } = Select;

const ContactInfo = ({ form, formData, setFormData }) => {
  const handlePhoneAreaChange = (newValue) => {
    const selectedCountry = PhoneAreas.find((area) => area.code === newValue);
    if (selectedCountry) {
      setFormData({
        ...formData,
        phoneArea: newValue,
        // country: selectedCountry.country,
      });
    }
  };

  const preventTextInput = (event) => {
    if (!/^[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: ".jpg,.jpeg,.png,.pdf",
    beforeUpload: async (file) => {
      try {
        // File size check (5MB)
        const isLargeFile = file.size / 1024 / 1024 > MAX_VALID_ID_SIZE;
        if (isLargeFile) {
          message.error(`File must be smaller than ${MAX_VALID_ID_SIZE}MB!`);
          return Upload.LIST_IGNORE;
        }

        const { base64String } = await getFileBase64AndType(file);

        const fileExtension = file.name.split(".").pop()?.toLowerCase() || "";

        setFormData({
          ...formData,
          base64Doc: base64String,
          docExtension: fileExtension,
        });

        message.success(`${file.name} file uploaded successfully`);
        return false; // Prevent default upload
      } catch (error) {
        message.error(`${file.name} file upload failed`);
        return Upload.LIST_IGNORE;
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const getIdTypeDescription = (value) => {
    const selectedType = Object.values(MeansOfIdentificationEnum).find(
      (item) => item.value === value
    );
    return selectedType?.description || "ID";
  };

  return (
    <>
      <div className="mt-4 mb-6">
        <p className="mb-2 text-2xl font-semibold text-left">
          Contact Information
        </p>
        {formData?.userType === "Personal" && (
          <p className="text-left text-[#929497]">
            Fill in the correct email address and phone number with which you
            can be contacted.
          </p>
        )}
        {formData?.userType === "Corporate" && (
          <p className="text-left text-[#929497]">
            Fill in the correct email address and phone number with which you
            can be contacted (Use your company email for registration).
          </p>
        )}
      </div>
      <Form form={form} layout="vertical">
        <div className="grid grid-cols-1 gap-1">
          <Form.Item
            label="Email Address"
            name="email"
            validateTrigger={["onBlur"]}
            rules={[
              {
                required: true,
                message: "Please enter your email address.",
              },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input
              placeholder="Enter your email address"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="phoneNo"
            onKeyPress={preventTextInput}
            rules={[
              {
                required: true,
                message: "Please input a mobile number.",
              },
              {
                validator: async (_, value) => {
                  if (!value) return;

                  // Remove any non-digit characters
                  const cleanValue = value.replace(/\D/g, "");

                  if (cleanValue.startsWith("0")) {
                    if (cleanValue.length !== 11) {
                      throw new Error(
                        "Phone number starting with 0 must be exactly 11 digits!"
                      );
                    }
                  } else {
                    if (cleanValue.length !== 10) {
                      throw new Error(
                        "Phone number not starting with 0 must be exactly 10 digits!"
                      );
                    }
                  }
                },
              },
            ]}
          >
            <Input
              addonBefore={
                <Select
                  style={{ width: 100 }}
                  value={formData.phoneArea}
                  onChange={handlePhoneAreaChange}
                >
                  {PhoneAreas.map((item) => (
                    <Option value={item.code} key={item.code}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{item.code}</span>
                        <img
                          src={item.flag}
                          alt={item.country}
                          style={{ width: "20px", marginLeft: "8px" }}
                        />
                      </div>
                    </Option>
                  ))}
                </Select>
              }
              maxLength={11}
              value={formData.phoneNo}
              onChange={(e) =>
                setFormData({ ...formData, phoneNo: e.target.value })
              }
            />
          </Form.Item>
        </div>
        <Divider />
        <div className="grid grid-cols-1 gap-1">
          <Form.Item
            label="Address Line 1"
            name={"addressLine1"}
            rules={[
              {
                required: true,
                message: "Please enter your address.",
              },
            ]}
          >
            <Input
              placeholder="Enter Address"
              value={formData.addressLine1}
              onChange={(e) =>
                setFormData({ ...formData, addressLine1: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Address Line 2 (optional)" name={"addressLine2"}>
            <Input
              placeholder="Enter Address"
              value={formData.addressLine2}
              onChange={(e) =>
                setFormData({ ...formData, addressLine2: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="City"
            name={"city"}
            rules={[
              {
                required: true,
                message: "Please enter your city.",
              },
            ]}
          >
            <Input
              placeholder="Enter City"
              value={formData.city}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="State"
            name={"state"}
            rules={[
              {
                required: true,
                message: "Please enter your state.",
              },
            ]}
          >
            <Input
              placeholder="Enter State"
              value={formData.state}
              onChange={(e) =>
                setFormData({ ...formData, state: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Postal Code (optional)" name={"postalCode"}>
            <Input
              placeholder="Enter Postal Code"
              value={formData.postalCode}
              onChange={(e) =>
                setFormData({ ...formData, postalCode: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Country"
            name={"country"}
            rules={[
              {
                required: true,
                message: "Please select an option.",
              },
            ]}
          >
            <Select
              placeholder="Select an option"
              value={formData.country}
              onChange={(value) => setFormData({ ...formData, country: value })}
            >
              {PhoneAreas.map((area) => area.country)
                .sort()
                .map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <Divider />
        <div className="grid grid-cols-1 gap-1">
          <Form.Item
            label="Biometrics Id (BVN)"
            name="biometricsId"
            onKeyPress={preventTextInput}
            rules={[
              {
                required: true,
                message: "Please enter your BVN.",
              },
              {
                pattern: /^\d{11}$/,
                message: "BVN must be exactly 11 digits.",
              },
            ]}
            style={{ marginBottom: "35px" }}
          >
            <Input
              placeholder="Enter your ID Number"
              maxLength={11}
              value={formData.biometricsId}
              onChange={(e) =>
                setFormData({ ...formData, biometricsId: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Means of Identification"
            name={"idType"}
            rules={[
              {
                required: true,
                message: "Please select an option.",
              },
            ]}
          >
            <Select
              placeholder="Select an option"
              value={formData.idType}
              onChange={(value) => setFormData({ ...formData, idType: value })}
            >
              {Object.values(MeansOfIdentificationEnum).map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.description}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={`${getIdTypeDescription(formData.idType)} Number`}
            name={"idNumber"}
            rules={[
              {
                required: true,
                message: "Please enter your valid ID number.",
              },
            ]}
          >
            <Input
              placeholder="Enter ID"
              value={formData.idNumber}
              onChange={(e) =>
                setFormData({ ...formData, idNumber: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Issue Date" name={"issueDate"}>
            <DatePicker
              className="w-full"
              value={formData.issueDate}
              onChange={(value) =>
                setFormData({ ...formData, issueDate: value })
              }
              placeholder="Select issue date"
            />
          </Form.Item>
          <Form.Item label="Expiry Date" name={"expiryDate"}>
            <DatePicker
              className="w-full"
              value={formData.expiryDate}
              onChange={(value) =>
                setFormData({ ...formData, expiryDate: value })
              }
              placeholder="Select expiry date"
              disabledDate={(current) => {
                // Disable dates before issued date
                return formData.issuedDate
                  ? current && current < formData.issuedDate
                  : false;
              }}
            />
          </Form.Item>

          <Form.Item
            label={`Upload your ${getIdTypeDescription(formData.idType)}`}
            rules={[
              {
                required: true,
                message: "Please upload valid ID.",
              },
            ]}
          >
            <Dragger {...draggerProps}>
              <p>Drag & Drop your file or Browse</p>
              <p className="text-sm text-gray-500">
                Supported format: .png, .jpg, .jpeg, .pdf (max{" "}
                {MAX_VALID_ID_SIZE}MB)
              </p>
            </Dragger>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default ContactInfo;
