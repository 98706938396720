import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";
import { useApiRequests } from "../../../utils/api/https/executor";
import { downloadFile } from "../../../utils/helper/util";
import { downloadFileType } from "../../../shared/constants/constants";

const TransferContext = createContext();

export const TransferProvider = ({ children }) => {
  const [transfers, setTransfers] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    accountValidation: false,
  });
  const [isBankLoading, setIsBankLoading] = useState(false);
  const [banks, setBanks] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const triggerNotification = useNotification();
  const { fetchBankList, validateBankAccountNumber } = useApiRequests();
  const [filter, setFilter] = useState({
    requestStatus: "",
    searchText: "",
    startDate: "",
    endDate: "",
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const getBankList = useCallback(async () => {
    try {
      setIsBankLoading(true);
      const response = await fetchBankList();

      const result = response?.outData;
      if (result?.isSuccessful) {
        console.log("banks are ", result?.data);
        setBanks(result?.data);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error.message || "Failed to fetch bank list",
      });
    } finally {
      setIsBankLoading(false);
    }
  });

  const validateAccount = useCallback(
    async (bankCode, accountNumber, onSuccess) => {
      // Basic validation
      if (accountNumber.length !== 10 || !bankCode) {
        return;
      }

      try {
        // Set loading state
        setLoadingStates((prev) => ({ ...prev, accountValidation: true }));

        // Reset previous states
        setValidationError(null);

        // Perform validation
        const response = await validateBankAccountNumber({
          bankCode,
          accountNumber,
          AccountValidationSource: 0,
        });

        const result = response?.outData;

        if (result?.isSuccessful) {
          console.log("Account validation result: ", result);
          // Set validation result
          const accountData = {
            accountName: result?.data?.accountName ?? "",
            currency: result?.data?.currency ?? "",
          };

          // Optional success callback
          if (onSuccess) {
            onSuccess(accountData);
          }

          // Trigger success notification
          triggerNotification({
            type: "success",
            message: "Success",
            description: result?.message,
          });

          return accountData;
        } else {
          // Handle validation failure
          setValidationError(result?.message);

          // Trigger error notification
          triggerNotification({
            type: "error",
            message: "Account Validation Failed",
            description: result?.message,
          });

          return null;
        }
      } catch (error) {
        // Handle unexpected errors

        triggerNotification({
          type: "error",
          message: "Validation Error",
          description: error.message,
        });

        return null;
      } finally {
        // Reset loading state
        setLoadingStates((prev) => ({ ...prev, accountValidation: false }));
      }
    },
    []
  );

  const getTransfers = useCallback(async () => {
    try {
      setIsLoading(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject({ ...filter, ...pagination });
      const response = await postAuthenticatedRequest(
        "GetTransferRequestsQuery",
        {
          operation: "GET",
          requestType: "TRANSFER",
          ...cleanedFilter,
        }
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        setTransfers(result);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch transfers",
      });
    } finally {
      setIsLoading(false);
    }
  }, [triggerNotification, filter, pagination]);

  // download transfer report
  const downloadTransferReport = useCallback(async () => {
    try {
      setIsDownloadingReport(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject({
        ...filter,
        ...pagination,
        isExport: true,
        fileExportName: "TransferReport",
        exportFields: {
          moduleType: "Module Type",
          serviceProviderCode: "Service Provider",
          transactionStatusDescription: "Status",
          transactionDate: "Transaction Date",
          requestId: "Transaction ID",
          transactionAmount: "Amount",
          sourceAccountNumber: "Source Account",
          serviceSubscriberReference: "Beneficiary",
          transactionRemarks: "Narration",
        },
      });
      const response = await postAuthenticatedRequest(
        "GetTransferRequestsQuery",
        {
          operation: "GET",
          requestType: "TRANSFER",
          ...cleanedFilter,
        }
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        downloadFile(result?.data, "Transfer_Report", downloadFileType.EXCEL);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch transfers",
      });
    } finally {
      setIsDownloadingReport(false);
    }
  }, [triggerNotification, filter, pagination]);

  return (
    <TransferContext.Provider
      value={{
        transfers,
        isLoading,
        isDownloadingReport,
        getTransfers,
        validateAccount,
        validationError,
        loadingStates,
        filter,
        setFilter,
        pagination,
        setPagination,
        getBankList,
        isBankLoading,
        banks,
        downloadTransferReport,
      }}
    >
      {children}
    </TransferContext.Provider>
  );
};

export const useTransfer = () => {
  return useContext(TransferContext);
};
