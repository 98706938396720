import VerifyOTP from "../common/VerifyOTP";
import { useOtp } from "../../store/context/otp-context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormData } from "../../store/context/form-data-context";
import { useNotification } from "../../store/context/notification-context";
import { useApiRequests } from "../../utils/api/https/executor";
import { getSessionData } from "../../utils/interceptors/localStorageService";
import { navigateToAuth } from "../../utils/helper/redirect";

const OtpVerificationPage = () => {
  const {
    otp,
    resetOTP,
    setOtpError,
    setOtp,
    setSeconds,
    setIsResendDisabled,
  } = useOtp();
  const navigate = useNavigate();
  const location = useLocation();
  const triggerNotification = useNotification();

  const { formData } = useFormData();
  const { url, data } = location.state;
  const { resendOTP, validateOTP } = useApiRequests();
  const { userId, requestId } = formData;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sessionData = getSessionData();
    const isLoggedIn = !!sessionData;
    if (Object.keys(formData).length < 1 && isLoggedIn) {
      navigate("/settings/security", {
        state: { resetSuccess: false },
        replace: true,
      });
      resetOTP();
    } else if (Object.keys(formData).length < 1 && !isLoggedIn) {
      // navigate("/authentication/login");
      navigateToAuth({ isAdmin: false });
      resetOTP();
    }
  }, [url, formData, navigate, resetOTP]);

  const handleResend = async () => {
    setOtpError(null);
    setOtp("");
    setSeconds(59);
    setIsResendDisabled(true);

    try {
      const dataToPost = { userId, requestId };
      const response = await resendOTP(dataToPost);
      const result = response?.outData;

      if (result?.isSuccessful) {
        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "OTP has been resent successfully.",
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message || "Failed to resend OTP.",
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "An error occurred.",
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const verificationResponse = await validateOTP({
        userId,
        requestId,
        otp,
      });

      const result = verificationResponse?.outData;

      if (result?.isSuccessful) {
        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "OTP verified successfully.",
        });
        navigate(url, {
          state: { resetSuccess: true, data },
          replace: true,
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message || "OTP verification failed.",
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Verification Failed",
        description: error?.message || "An error occurred.",
      });
    } finally {
      resetOTP();
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4 text-center">
      <VerifyOTP
        formData={formData}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        handleResend={handleResend}
      />
    </div>
  );
};

export default OtpVerificationPage;
