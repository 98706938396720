import sspFlag from "../../assets/flags/ssp.png";
import njaFlag from "../../assets/flags/9ja.png";
import cdfFlag from "../../assets/flags/cdf.png";
import rwfFlag from "../../assets/flags/rwf.png";
import kesFlag from "../../assets/flags/kes.png";
import tzsFlag from "../../assets/flags/tzs.png";
import ugxFlag from "../../assets/flags/ugx.png";

export const countryData = [
  {
    id: 1,
    name: "Nigeria",
    flag: njaFlag,
    phone: "+234 000 0000 000",
    email: "info-nigeria@banklingo.app",
    facebookLink: "https://www.facebook.com/banklingo/",
    websiteLink: "https://www.banklingo.app/",
  },
  {
    id: 1,
    name: "South Sudan",
    flag: sspFlag,
    phone: "+211 916 986 000",
    email: "info-southsudan@banklingo.app",
    facebookLink: "https://www.facebook.com/banklingo/",
    websiteLink: "https://www.banklingo.app/",
  },
  {
    id: 2,
    name: "Congo (Democratic Republic of Congo)",
    flag: cdfFlag,
    phone: "+243 996 018 000",
    email: "mail@banklingo.app",
    twitterLink: "https://x.com/banklingo?lang=en",
    facebookLink: "https://www.facebook.com/banklingo/",
    linkedinLink: "https://www.linkedin.com/company/banklingo/",
    websiteLink: "https://banklingo.app/cd/",
  },
  {
    id: 3,
    name: "Rwanda",
    flag: rwfFlag,
    phone: "+250 788 190 000",
    email: "inforwanda@banklingo.app",
    twitterLink: "https://x.com/banklingo?lang=en",
    facebookLink: "https://www.facebook.com/banklingo/?ref=page_internal",
    websiteLink: "https://banklingo.app/rw/",
  },
  {
    id: 4,
    name: "Kenya",
    flag: kesFlag,
    phone: "+254 763 000 000",
    email: "info@banklingo.app",
    twitterLink: "https://x.com/banklingo?lang=en",
    facebookLink: "https://www.facebook.com/banklingo/?ref=page_internal",
    linkedinLink:
      "https://www.linkedin.com/company/banklingo/?originalSubdomain=ke",
    websiteLink: "https://banklingo.app/",
  },
  {
    id: 5,
    name: "Tanzania",
    flag: tzsFlag,
    phone: "+255 768 985 500",
    email: "infotz@banklingo.co.tz",
    twitterLink: "https://x.com/banklingo?lang=en",
    facebookLink: "https://www.facebook.com/banklingo/",
    websiteLink: "https://banklingo.app/tz/",
  },
  {
    id: 6,
    name: "Uganda",
    flag: ugxFlag,
    phone: "+256 312 327 000",
    email: "info@banklingo.co.ug",
    twitterLink: "https://x.com/banklingo?lang=en",
    facebookLink: "https://www.facebook.com/banklingo/",
    websiteLink: "https://banklingo.app/ug/",
  },
];

export const PhoneAreas = [
  { code: "+234", flag: njaFlag, country: "Nigeria" },
  { code: "+211", flag: sspFlag, country: "South Sudan" },
  { code: "+243", flag: cdfFlag, country: "DRC" },
  { code: "+250", flag: rwfFlag, country: "Rwanda" },
  { code: "+254", flag: kesFlag, country: "Kenya" },
  { code: "+255", flag: tzsFlag, country: "Tanzania" },
  { code: "+256", flag: ugxFlag, country: "Uganda" },
];

export const productData = [
  {
    id: 1,
    name: "Payroll",
    code: "PR-1",
    interestRateCalculationPeriod: 1,
    interestRateCalculationDescription: "Weekly",
    interestRateCalculationsType: 0,
    interestRateCalculationsTypeDescription: "Flat",
    currencyCode: "NGN",
    interestRate: 23,
    disbursementFeeCalculation: 0,
    disbursementFeeCalculationDescription: "Flat",
    disbursementFee: 30000,
    minimumAmount: null,
    maximumAmount: null,
    maximumTenorInDays: 30,
    createdAt: "2024-10-23T13:13:32.8273173",
  },
  {
    id: 2,
    name: "Pay day",
    code: "PR-2",
    interestRateCalculationPeriod: 0,
    interestRateCalculationDescription: "Daily",
    interestRateCalculationsType: 0,
    interestRateCalculationsTypeDescription: "Flat",
    currencyCode: "NGN",
    interestRate: 0,
    disbursementFeeCalculation: 0,
    disbursementFeeCalculationDescription: "Flat",
    disbursementFee: 0,
    minimumAmount: null,
    maximumAmount: null,
    maximumTenorInDays: 30,
    createdAt: "2024-10-23T13:13:33.0474379",
  },
  {
    id: 3,
    name: "SME",
    code: "PR-3",
    interestRateCalculationPeriod: 0,
    interestRateCalculationDescription: "Daily",
    interestRateCalculationsType: 0,
    interestRateCalculationsTypeDescription: "Flat",
    currencyCode: "NGN",
    interestRate: 0,
    disbursementFeeCalculation: 0,
    disbursementFeeCalculationDescription: "Flat",
    disbursementFee: 0,
    minimumAmount: null,
    maximumAmount: null,
    maximumTenorInDays: 300,
    createdAt: "2024-10-23T13:38:47.250802",
  },
  {
    id: 4,
    name: "Assets Finance",
    code: "PR-4",
    interestRateCalculationPeriod: 0,
    interestRateCalculationDescription: "Daily",
    interestRateCalculationsType: 0,
    interestRateCalculationsTypeDescription: "Flat",
    currencyCode: "NGN",
    interestRate: 0,
    disbursementFeeCalculation: 0,
    disbursementFeeCalculationDescription: "Flat",
    disbursementFee: 0,
    minimumAmount: null,
    maximumAmount: null,
    maximumTenorInDays: 300,
    createdAt: "2024-10-23T13:38:47.2614081",
  },
  {
    id: 5,
    name: "Proof of funds",
    code: "PR-5",
    interestRateCalculationPeriod: 1,
    interestRateCalculationDescription: "Weekly",
    interestRateCalculationsType: 0,
    interestRateCalculationsTypeDescription: "Flat",
    currencyCode: "NGN",
    interestRate: 20,
    disbursementFeeCalculation: 0,
    disbursementFeeCalculationDescription: "Flat",
    disbursementFee: 34,
    minimumAmount: 12000,
    maximumAmount: 300000,
    maximumTenorInDays: 30,
    createdAt: "2024-10-24T18:38:48.6441462",
  },
];

export const documentTyps = [
  { name: "CUSTOM", key: 0 },
  { name: "NATIONAL ID", key: 1 },
  { name: "INTERNATIONAL PASSPORT", key: 2 },
  { name: "DRIVERS LICENCE", key: 3 },
  { name: "UTILITY BILL", key: 4 },
  { name: "BANK STATEMENT", key: 5 },
  { name: "COMPANY REGISTRATION", key: 6 },
  { name: "TAX CLEARANCE", key: 7 },
  { name: "CERTIFICATE OF INCORPORATION", key: 8 },
  { name: "MEMORANDUM OF ASSOCIATION", key: 9 },
  { name: "ARTICLES OF ASSOCIATION", key: 10 },
  { name: "BOARD RESOLUTION", key: 11 },
  { name: "IDENTITY CARD", key: 12 },
  { name: "SIGNATURE", key: 13 },
  { name: "PHOTOGRAPH", key: 14 },
  { name: "UNIVERISTY CERTIFICATE", key: 15 },
  { name: "BIRTH CERTIFICATE", key: 16 },
  { name: "MARRIAGE CERTIFICATE", key: 17 },
  { name: "DEATH CERTIFICATE", key: 18 },
  { name: "WILL", key: 19 },
  { name: "RESIDENCE PERMIT", key: 20 },
  { name: "WORK PERMIT", key: 21 },
  { name: "VISA", key: 22 },
  { name: "PERMANENT RESIDENCE", key: 23 },
];

export const accountDocumentTyps = [
  { name: "NATIONAL ID", key: 1 },
  { name: "INTERNATIONAL PASSPORT", key: 2 },
  { name: "DRIVERS LICENCE", key: 3 },
];

export const adminUserTypes = {
  roles: {
    customers: [
      {
        id: 1,
        name: "CUSTOMER",
        description: "General customer role.",
      },
    ],
    corporateProfileUsers: [
      {
        id: 3,
        name: "CORPORATE ADMINISTRATOR",
        description: "Administrator for corporate profiles.",
      },
      {
        id: 4,
        name: "INITIATOR",
        description: "Initiates actions in workflows.",
      },
      {
        id: 5,
        name: "REVIEWER",
        description: "Reviews actions in workflows.",
      },
      {
        id: 6,
        name: "APPROVER",
        description: "Approves actions in workflows.",
      },
      {
        id: 7,
        name: "VIEWER ANALYST",
        description: "Views and analyzes workflows.",
      },
    ],
    backOfficeAdministrators: [
      {
        id: 30,
        name: "Super Administrator",
        description: "Manages customer relationships.",
      },
      {
        id: 12,
        name: "Relationship manager",
        description: "Manages customer relationships.",
      },
      {
        id: 13,
        name: "Control officer",
        description: "Manages compliance and control.",
      },
      {
        id: 14,
        name: "Branch manager",
        description: "Oversees branch operations.",
      },
      {
        id: 15,
        name: "Area manager",
        description: "Oversees area operations.",
      },
      {
        id: 16,
        name: "Division manager",
        description: "Oversees division-level operations.",
      },
      {
        id: 17,
        name: "Credit admin",
        description: "Manages credit operations.",
      },
      {
        id: 18,
        name: "Managing director",
        description: "Managing Director.",
      },
      {
        id: 19,
        name: "GMD (BOARD)",
        description: "Board-level role.",
      },
      {
        id: 20,
        name: "Mandate Administrator",
        description: "Manages mandates.",
      },
      {
        id: 21,
        name: "Mandate Initiator",
        description: "Initiates mandates.",
      },
      {
        id: 22,
        name: "Mandate Reviewer",
        description: "Reviews mandates.",
      },
      {
        id: 23,
        name: "Mandate Collection Initiator",
        description: "Initiates mandate collection.",
      },
      {
        id: 24,
        name: "Mandate Collection Authorizer",
        description: "Authorize mandate collection.",
      },
      {
        id: 25,
        name: "Mandate Collection Reviewer",
        description: "Review mandate collection.",
      },
      {
        id: 26,
        name: "Mandate Auhorizer",
        description: "Authorize mandate.",
      },
    ],
    systemAndApiRoles: [
      {
        id: 30,
        name: "Super admin",
        description: "Full administrative access.",
      },
      {
        id: 31,
        name: "API DEVELOPER CUSTOMER PORTAL",
        description: "Developer for customer-facing APIs.",
      },
      {
        id: 32,
        name: "API DEVELOPER ADMIN PORTAL",
        description: "Developer for admin-facing APIs.",
      },
      {
        id: 33,
        name: "CUSTOM ROLES",
        description: "Custom role for specialized operations.",
      },
    ],
  },
};
