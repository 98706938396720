// enums.js

export const baseURL = `${process.env.REACT_APP_BASE_URL}/Fintech.CBS.Backend`;
export const signalrURL = `${process.env.REACT_APP_SINGAL_BASE_URL}/Fintech.CBS.Backend/notificationHub`;

// maximum file size for valid ID upload
export const MAX_VALID_ID_SIZE = 2;

// UserStatusEnum
export const UserStatusEnum = {
  ACTIVE: 1,
  BLOCKED: 2,
  BLACKLISTED: 3,
  SUSPENDED: 4,
};

// User status badge configuration
export const STATUS_CONFIG = {
  [UserStatusEnum.ACTIVE]: { color: "success", label: "Active" },
  [UserStatusEnum.BLOCKED]: { color: "error", label: "Blocked" },
  [UserStatusEnum.BLACKLISTED]: { color: "default", label: "Blacklisted" },
  [UserStatusEnum.SUSPENDED]: { color: "warning", label: "Suspended" },
};

// VerificationStatusEnum
export const VerificationStatusEnum = {
  PENDING: 0,
  VERIFICATION_FAILED: 1,
  VERIFICATION_SUCCESSFUL: 2,
};

// UsageStatusEnum
export const UsageStatusEnum = {
  PENDING: 0,
  USED: 1,
  DISCARDED: 2,
};

// RequestStatusEnum with descriptions
export const RequestStatusEnum = {
  PENDING_VERIFICATION: {
    value: 0,
    description: "Awaiting Verification",
  },
  PENDING_PROCESSING: {
    value: 1,
    description: "Pending",
  },
  PROCESSING: {
    value: 2,
    description: "Processing",
  },
  PROCESSED: {
    value: 3,
    description: "Processed",
  },
};

export const MeansOfIdentificationEnum = {
  NATIONAL_ID: {
    value: 1,
    description: "National ID",
  },
  INTERNATIONAL_PASSPORT: {
    value: 2,
    description: "International Passport",
  },
  DRIVERS_LICENSE: {
    value: 3,
    description: "Drivers License",
  },
};

// NetworkType with description
export const NetworkTypeEnum = {
  MTN: {
    value: 4,
    description: "Mtn",
  },
  AIRTEL: {
    value: 1,
    description: "Airtel",
  },
  // cannot start object key with a number
  MOBILE9: {
    value: 2,
    description: "9Mobile",
  },
  GLOBACOM: {
    value: 3,
    description: "Globacom",
  },
};

// TransactionType with description
export const TransactionTypeEnum = {
  AIRTIME: {
    value: "AIRTIME RECHARGE",
    description: "Airtime",
  },
  BILLS: {
    value: "BILLS PAYMENT",
    description: "Bills",
  },
  DATA: {
    value: "DATA RECHARGE",
    description: "Data",
  },
  TRANSFER: {
    value: "TRANSFER",
    description: "Transfer",
  },
};

// SelfUserClassificationEnum with descriptions
export const SelfUserClassificationEnum = {
  CUSTOMER: {
    value: 0,
    description: "Customer",
  },
  CORPORATE_USERS: {
    value: 1,
    description: "Corporate Users",
  },
  BACKOFFICE_ADMINISTRATORS: {
    value: 2,
    description: "Administrator",
  },
};

export const ProductCategoryEnum = {
  ALL: {
    value: 0,
    description: "All",
  },
  DEPOSIT: {
    value: 1,
    description: "Deposit",
  },
  LOAN: {
    value: 2,
    description: "Loan",
  },
  INSURANCE: {
    value: 3,
    description: "Insurance",
  },
};

// SelfServiceAccountTypeEnum with descriptions
export const SelfServiceAccountTypeEnum = {
  NONE: {
    value: 0,
    description: "Backoffice Users",
  },
  INDIVIDUAL: {
    value: 1,
    description: "Individual",
  },
  CORPORATE: {
    value: 2,
    description: "Corporate",
  },
};

export const downloadFileType = {
  PDF: "application/pdf",
  EXCEL: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PNG: "image/png",
  JPEG: "image/jpeg",
  JPG: "image/jpeg",
  WEBP: "image/webp",
};

export const tablePageSizeOptions = ["10", "25", "50", "100"];

export const featuresEnum = Object.freeze({
  CUSTOMER_PORTAL: -100,
  ADMIN_PORTAL: -200,
  CUSTOMER_AIRTIME_RECHARGE: 0,
  CUSTOMER_DATA_PURCHASE: 1,
  CUSTOMER_BILL: 2,
  CUSTOMER_TRANSFER: 3,
  CUSTOMER_LOAN: 4,
  CUSTOMER_INSURANCE: 5,
  CUSTOMER_SAVINGS: 6,
  CUSTOMER_INVESTMENT: 7,
  CUSTOMER_LIMIT_UPDATE: 8,
  CUSTOMER_ACCOUNT_UPGRADE: 9,
  CUSTOMER_SIGNUP_CORPORATE: 10,
  CUSTOMER_SIGNUP_INDIVIDUAL: 11,
  PORTAL_SUPPORT_MODAL: 12,
  PORTAL_LANGUAGE_TOGGLE: 13,
  PORTAL_DARK_MODE_SWITCH: 14,
  PORTAL_CALL_BACK_REQUEST: 15,

  ADMIN_USER_MANAGEMENT: 2000,
  ADMIN_ROLE_MANAGEMENT: 2001,
  ADMIN_LOAN_REQUESTS: 2002,
  ADMIN_INSURANCE_REQUESTS: 2003,
  ADMIN_SAVINGS_REQUESTS: 2004,
  ADMIN_TRANSFER_REQUESTS: 2005,
  ADMIN_BILL_REQUESTS: 2006,
  ADMIN_AIRTIME_REQUESTS: 2007,
  ADMIN_DATA_REQUESTS: 2008,
  ADMIN_LIMIT: 2009,
  ADMIN_NIBSS_MANDATE_SETUP: 2010,
  ADMIN_NIBSS_MANDATE_COLLECTION: 2011,
  ADMIN_PRODUCT_CONFIGURATION: 2012,
  ADMIN_MANAGE_CHANNELS: 2013,
  ADMIN_CUSTOMERS_REPORT: 2014,
  ADMIN_TRANSACTIONS_REPORT: 2015,
});

export const PermissionsEnum = Object.freeze({
  // Customer Permissions
  CUS_DASH: "CUS_DASH",
  CUS_BILL_INIT: "CUS_BILL_INIT",
  CUS_AIRTIME_INIT: "CUS_AIRTIME_INIT",
  CUS_DATA_INIT: "CUS_DATA_INIT",
  CUS_TRANSFER_INIT: "CUS_TRANSFER_INIT",
  CUS_STATEMENT_INIT: "CUS_STATEMENT_INIT",
  CUS_BILL_VERIFY: "CUS_BILL_VERIFY",
  CUS_AIRTIME_VERIFY: "CUS_AIRTIME_VERIFY",
  CUS_DATA_VERIFY: "CUS_DATA_VERIFY",
  CUS_TRANSFER_VERIFY: "CUS_TRANSFER_VERIFY",
  CUS_STATEMENT_VERIFY: "CUS_STATEMENT_VERIFY",

  // Admin Permissions
  ADM_LOAN_REQUESTAPPROVAL: "ADM_LOAN_REQUESTAPPROVAL",
  ADM_LOAN_APPROVE: "ADM_LOAN_APPROVE",
  ADM_LOAN_REJECT: "ADM_LOAN_REJECT",
  ADM_LOAN_RETURN: "ADM_LOAN_RETURN",
  ADM_LOAN_WITHDRAW: "ADM_LOAN_WITHDRAW",
  ADM_LOAN_VIEW: "ADM_LOAN_VIEW",
  ADM_CUSTOMER_VIEW: "ADM_CUSTOMER_VIEW",
  ADM_DASH: "ADM_DASH",
  ADM_SEARCH: "ADM_SEARCH",
  ADM_REPORT_CUST: "ADM_REPORT_CUST",
  ADM_REPORT_LOAN: "ADM_REPORT_LOAN",
  ADM_REPORT_TRANS: "ADM_REPORT_TRANS",
  ADM_REPORT_TRANSFER: "ADM_REPORT_TRANSFER",
  ADM_EXPORT_CUST: "ADM_EXPORT_CUST",
  ADM_EXPORT_LOAN: "ADM_EXPORT_LOAN",
  ADM_EXPORT_TRANS: "ADM_EXPORT_TRANS",
  ADM_EXPORT_TRANSFER: "ADM_EXPORT_TRANSFER",
  ADM_CONFIG: "ADM_CONFIG",
  ADM_LIMIT_MGMT: "ADM_LIMIT_MGMT",
  ADM_LIMIT_REQ: "ADM_LIMIT_REQ",
  ADM_PROVIDER: "ADM_PROVIDER",
  ADM_REACTIVATE: "ADM_REACTIVATE",
  ADM_MANDATE_VIEW_BATCH: "ADM_MANDATE_VIEW_BATCH",
  ADM_MANDATE_UPLOAD_BATCH: "ADM_MANDATE_UPLOAD_BATCH",
  ADM_MANDATE_SINGLE: "ADM_MANDATE_SINGLE",
  ADM_MANDATE_VIEW: "ADM_MANDATE_VIEW",
  ADM_MANDATE_EXPORT: "ADM_MANDATE_EXPORT",
  ADM_COLLECTION_VIEW_BATCH: "ADM_COLLECTION_VIEW_BATCH",
  ADM_COLLECTION_UPLOAD_BATCH: "ADM_COLLECTION_UPLOAD_BATCH",
  ADM_COLLECTION_SINGLE: "ADM_COLLECTION_SINGLE",
  ADM_COLLECTION_EXPORT: "ADM_COLLECTION_EXPORT",
  ADM_COLLECTION_TEMPLATE: "ADM_COLLECTION_TEMPLATE",
  ADM_ORG_MGMT: "ADM_ORG_MGMT",
  ADM_DIV_MGMT: "ADM_DIV_MGMT",
  ADM_AREA_MGMT: "ADM_AREA_MGMT",
  ADM_BRANCH_MGMT: "ADM_BRANCH_MGMT",
  ADM_USER_NEW: "ADM_USER_NEW",
  ADM_USER_VIEW: "ADM_USER_VIEW",
  ADM_USER_EDIT: "ADM_USER_EDIT",
  ADM_USER_SUSPEND: "ADM_USER_SUSPEND",
  ADM_USER_BLACKLIST: "ADM_USER_BLACKLIST",
  ADM_USER_ACTIVATE: "ADM_USER_ACTIVATE",
  ADM_USER_DEACTIVATE: "ADM_USER_DEACTIVATE",
  ADM_NOTIFICATIONS: "ADM_NOTIFICATIONS",
  // new permissions - 16/01/2025
  ADM_MANDATE_UPDATE: "ADM_MANDATE_UPDATE",
  ADM_MANDATE_REFRESH: "ADM_MANDATE_REFRESH",
  ADM_MANDATE_START: "ADM_MANDATE_START",
  ADM_MANDATE_STOP: "ADM_MANDATE_STOP",
  ADM_MANDATE_AUTHORIZE: "ADM_MANDATE_AUTHORIZE",
  ADM_MANDATE_DECLINE: "ADM_MANDATE_DECLINE",
  ADM_COLLECTIONBATCH_AUTHORIZE: "ADM_COLLECTIONBATCH_AUTHORIZE",
  ADM_COLLECTIONBATCH_DEACTIVATE: "ADM_COLLECTIONBATCH_DEACTIVATE",
  ADM_COLLECTIONBATCH_CANCEL: "ADM_COLLECTIONBATCH_CANCEL",
  ADM_COLLECTIONBATCH_COLLECTFUNDS: "ADM_COLLECTIONBATCH_COLLECTFUNDS",
  ADM_COLLECTIONBATCH_COLLECTFUNDS_SINGLE:
    "ADM_COLLECTIONBATCH_COLLECTFUNDS_SINGLE",
});

export const MandateStatus = {
  Draft: { value: 0, desc: "New Mandate Initiated by Biller" },
  NewMandateInitiated: { value: 1, desc: "New Mandate Initiated by Biller" },
  MandateAuthorisedByBiller: { value: 2, desc: "Mandate Authorized by Biller" },
  MandateRejectedByBiller: { value: 3, desc: "Mandate Rejected by Biller" },
  MandateApprovedByBiller: { value: 4, desc: "Mandate Approved by Biller" },
  MandateDisApprovedByBiller: {
    value: 5,
    desc: "Mandate Disapproved by Biller",
  },
  MandateAuthorisedByBank: { value: 6, desc: "Mandate Authorised by Bank" },
  MandateRejectedByBank: { value: 7, desc: "Mandate Rejected by Bank" },
  MandateApprovedByBank: { value: 8, desc: "Mandate Approved by Bank" },
  MandateDisApprovedByBank: { value: 9, desc: "Mandate DisApproved by Bank" },
  NewMandateInitatedByBank: {
    value: 10,
    desc: "New Mandate Initiated by Bank",
  },
};
