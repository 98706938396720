import dayjs from "dayjs";

export const formatCurrencyWithCode = (amount) => {
  if (amount) {
    return `${new Intl.NumberFormat("en-NG", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount)} NGN`;
  } else {
    return amount;
  }
};

export const formatCurrencyWithoutCode = (amount) => {
  if (amount) {
    return new Intl.NumberFormat("en-NG", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  } else {
    return amount;
  }
};

export const formatDate = (date, format = "DD-MM-YYYY") => {
  if (!date) {
    return ""; // Return an empty string for null or undefined dates
  }

  const parsedDate = dayjs(date);

  // Validate the date
  if (!parsedDate.isValid()) {
    console.warn("Invalid date provided:", date);
    return "Invalid date"; // Gracefully handle invalid date inputs
  }

  // Use the specified format or default to "DD-MM-YYYY"
  return parsedDate.format(format);
};

// remove keys that has falsy value
// first arguement is the object to be cleaned
// second argument is an array of keys you also want to remove from the object
export const cleanObject = (obj, keysToExclude = []) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) =>
        value !== "" && value != null && !keysToExclude.includes(key)
    )
  );
};

export const maskString = (input, maskChar = "*", visibleChars = 3) => {
  if (!input || typeof input !== "string") {
    console.warn("Invalid input. Expected a non-empty string.");
    return input;
  }

  // Ensure the string has enough characters to mask
  if (input?.length <= visibleChars) {
    return input; // Return the input unmasked if it's too short
  }

  // Create a masked version
  const maskedLength = input?.length - visibleChars;
  const maskedPart = maskChar.repeat(maskedLength);
  const visiblePart = input.slice(-visibleChars);

  return `${maskedPart}${visiblePart}`;
};


export const  capitalize = (str)=>{
  return str.charAt(0).toUpperCase() + str.slice(1);
  }