import { Menu, Switch, Divider, Dropdown, notification } from "antd";
import { useState, useEffect } from "react";
import {
  LogoutOutlined,
  SunOutlined,
  MoonOutlined,
  HomeOutlined,
  MedicineBoxOutlined,
  OrderedListOutlined,
  TransactionOutlined,
  WifiOutlined,
  DownOutlined,
  SnippetsOutlined,
  LoginOutlined,
  DollarCircleOutlined,
  PhoneOutlined,
  UserAddOutlined,
  GlobalOutlined,
  SettingOutlined,
  ClusterOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  SearchOutlined,
  FileTextOutlined,
  PullRequestOutlined,
} from "@ant-design/icons";
import { useTheme } from "../../store/context/theme-context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logoutUser } from "../../store/redux/actions/userActions";
import LanguageSwitcher from "../../shared/languages/LanguageSwitcher";
import { useLanguage } from "../../store/context/language-context";
import { languageMap } from "../../shared/languages/LanguageSelector";
import { getSessionData } from "../../utils/interceptors/localStorageService";
import { useTenant } from "../../store/context/tenant-context";
import {
  featuresEnum,
  PermissionsEnum,
  SelfUserClassificationEnum,
} from "../../shared/constants/constants";
import { useFeatures } from "../../store/context/feature-context";
import { navigateToAuth } from "../../utils/helper/redirect";
import { usePermissions } from "../../store/context/permission-context";

const Sidebar = ({ onSelect, collapsed, type, selectedKey }) => {
  const { theme, toggleTheme } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { findFeature } = useFeatures();
  const { permissions, findPermission } = usePermissions();
  // console.log("permission from context-sidebar", permissions);
  const { language: languageCode, showModal } = useLanguage();
  const [sessionData, setSessionData] = useState(null);
  const [initials, setInitials] = useState("");

  // const addFeatureMenuItem = (featureKey, item) =>
  //   findFeature(featureKey) ? [item] : [];
  const addFeatureMenuItem = (featureKey, item, permissionKey) => {
    const shouldRender = permissionKey
      ? findFeature(featureKey) && findPermission(permissionKey)
      : findFeature(featureKey);

    return shouldRender ? [item] : [];
  };
  const tenant = useTenant();

  useEffect(() => {
    const storedSessionData = getSessionData();
    if (storedSessionData) {
      setSessionData(storedSessionData);
      const firstNameInitial = (storedSessionData?.firstName ?? "")
        .charAt(0)
        .toUpperCase();
      const lastNameInitial = (storedSessionData?.lastName ?? "")
        .charAt(0)
        .toUpperCase();
      setInitials(`${firstNameInitial}${lastNameInitial}`);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logoutEvent") {
        navigateToAuth({ isAdmin: false });

        window.location.reload();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  const handleSignIn = () => {
    if (findFeature(featuresEnum.CUSTOMER_PORTAL))
      navigateToAuth({ isAdmin: false });
    else if (findFeature(featuresEnum.ADMIN_PORTAL))
      navigateToAuth({ isAdmin: true });
    else {
      //show a message to show that this feature is not available
      notification.info({ message: "Sign In feature not available." });
    }
  };

  const handleRegister = () => {
    if (findFeature(featuresEnum.CUSTOMER_PORTAL)) navigate("/segment");
    // if(findFeature(featuresEnum.ADMIN_PORTAL))
    else {
      //navigate("/authentication/admin/register");
      //show a message to show that this feature is not available
      notification.info({ message: "Registration feature not available." });
    }
  };

  const handleSignOut = () => {
    dispatch(logoutUser());
    sessionData?.userClassification ===
    SelfUserClassificationEnum.BACKOFFICE_ADMINISTRATORS.value
      ? navigateToAuth({ isAdmin: true })
      : navigateToAuth({ isAdmin: false });
  };
  const profileMenuItems = [
    {
      key: "profile",
      icon: <UserOutlined />,
      label: <Link to="/profile">Profile</Link>,
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: <Link to="/settings">Settings</Link>,
    },
    {
      key: "divider",
      type: "divider",
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: "Logout",
      onClick: handleSignOut,
    },
  ];

  const ProfileDropdown = () => {
    return (
      <Dropdown
        menu={{ items: profileMenuItems }} // Correctly structured menu
        trigger={["click"]}
        placement="bottomRight"
        overlayStyle={{ minWidth: "160px" }}
      >
        <div
          onClick={(e) => e.preventDefault()}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            backgroundColor: tenant?.theme?.colorPrimary || "#1890ff",
            color: "#fff",
          }}
        >
          <DownOutlined style={{ fontSize: "14px" }} />
        </div>
      </Dropdown>
    );
  };

  const profileName = `${sessionData?.displayName}` || "";
  // const accountType =
  //   sessionData?.accountType === 1
  //     ? "Personal"
  //     : sessionData?.accountType === 2 || sessionData?.accountType === 0
  //       ? "Corporate"
  //       : null;
  const settingsPath = `/settings`;
  const languageName = languageMap[languageCode];
  const items = [
    {
      key: collapsed ? "collapsed" : "expanded",
      style: { height: "auto" },
      className: "non-interactive",
      label: (
        <div className="flex items-center justify-between">
          {!collapsed && (
            <>
              <img
                src={theme === "dark" ? tenant.darkLogo : tenant.logo}
                alt={tenant?.companyName}
                style={{ width: 90, height: 90, marginTop: 20 }}
              />
            </>
          )}
        </div>
      ),
    },
    ...(type === "portal"
      ? [
          {
            key: "divider1",
            width: "auto",
            label: <Divider />,
            className: "non-interactive",
          },
          {
            key: "home",
            icon: <HomeOutlined />,
            label: <Link to="/">{t("Home")}</Link>,
          },
          {
            key: "signin",
            icon: <LoginOutlined />,
            label: "Sign In",
            onClick: handleSignIn,
          },
          {
            key: "register",
            icon: <UserAddOutlined />,
            label: "Register",
            onClick: handleRegister,
          },
          ...addFeatureMenuItem(featuresEnum.PORTAL_CALL_BACK_REQUEST, {
            key: "confirm-details",
            icon: <PhoneOutlined />,
            label: <Link to="/confirm-details">Request a CallBack</Link>,
          }),
          {
            key: "divider2",
            width: "auto",
            label: <Divider />,
            className: "non-interactive",
          },
          ...addFeatureMenuItem(featuresEnum.PORTAL_LANGUAGE_TOGGLE, {
            key: "english",
            icon: <GlobalOutlined onClick={showModal} />,
            label: (
              <div>
                <p
                  type="text"
                  onClick={showModal}
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {languageName}
                </p>
                <LanguageSwitcher />
              </div>
            ),
          }),
          ...addFeatureMenuItem(featuresEnum.PORTAL_DARK_MODE_SWITCH, {
            key: "theme",
            icon:
              theme === "dark" ? (
                <MoonOutlined onClick={toggleTheme} />
              ) : (
                <SunOutlined onClick={toggleTheme} />
              ),
            label: (
              <div className="flex items-center justify-between">
                <span className="mr-2">Dark Mode</span>
                <Switch
                  checked={theme === "dark"}
                  onChange={toggleTheme}
                  size="small"
                />
              </div>
            ),
          }),
          {
            key: "userswitch",
            icon:
              theme === "dark" ? (
                <UserSwitchOutlined />
              ) : (
                // the best ant design icon to use for switching admin is
                <UserSwitchOutlined />
              ),
            label: (
              <div className="flex items-center justify-between">
                <span className="mr-2">Admin Switch</span>
                <Switch
                  // checked={theme === "dark"}
                  onChange={() => navigateToAuth({ isAdmin: false })}
                  size="small"
                />
              </div>
            ),
          },
        ]
      : []),
    ...(type === "inner"
      ? [
          {
            key: "profile",
            className: "non-interactive",
            style: { height: "auto" },
            label: !collapsed && (
              <div
                className={`${
                  theme === "dark" ? "text-white bg-[#1d1d1d]" : "bg-[#f5f5f5]"
                } flex items-center justify-start px-1 py-3 rounded-lg my-7 mx-0 gap-3`}
              >
                <div
                  className="flex items-center justify-center text-lg text-white rounded-full size-11 min-w-11"
                  style={{ backgroundColor: tenant.theme.colorPrimary }}
                >
                  {initials || "PN"}
                </div>
                <div className="h-12 text-base flex-1f">
                  <p>
                    <span className="block text-sm font-medium">
                      {profileName}
                    </span>

                    <span
                      className={`${
                        theme === "dark" ? "text-[#8c8c8c]" : "text-[#595959]"
                      } text-sm block`}
                    >
                      {sessionData?.userClassificationDescription}
                    </span>
                  </p>
                </div>
                <div className="ml-[3px] mr-4">
                  <DownOutlined style={{ color: tenant.theme.colorPrimary }} />
                </div>
              </div>
            ),
          },
          ...(sessionData?.userClassification ===
          SelfUserClassificationEnum.BACKOFFICE_ADMINISTRATORS.value
            ? [
                findPermission(PermissionsEnum.ADM_DASH) && {
                  key: "adminDashboard",
                  icon: <HomeOutlined />,
                  label: <Link to="/admin-dashboard">{t("Dashboard")}</Link>,
                },
                findPermission(PermissionsEnum.ADM_SEARCH) && {
                  key: "AdminGeneralSearch",
                  icon: <SearchOutlined />,
                  label: (
                    <Link to="/general-search">{t("General Search")}</Link>
                  ),
                },
                ...addFeatureMenuItem(
                  featuresEnum.ADMIN_CUSTOMERS_REPORT,
                  {
                    key: "AdminCustomers",
                    icon: <UsergroupAddOutlined />,
                    label: <Link to="/customers">{t("Customers")}</Link>,
                  },
                  PermissionsEnum.ADM_REPORT_CUST
                ),
                ...addFeatureMenuItem(
                  featuresEnum.ADMIN_LOAN_REQUESTS,
                  {
                    key: "AdminLoanRequests",
                    icon: <FileTextOutlined />,
                    label: (
                      <Link to="/loan-requests">{t("Loan Requests")}</Link>
                    ),
                  },
                  PermissionsEnum.ADM_REPORT_LOAN
                ),
                ...addFeatureMenuItem(
                  featuresEnum.ADMIN_TRANSACTIONS_REPORT,
                  {
                    key: "AdminTransactions",
                    icon: <SnippetsOutlined />,
                    label: <Link to="/transactions">{t("Transactions")}</Link>,
                  },
                  PermissionsEnum.ADM_REPORT_TRANS
                ),
                ...addFeatureMenuItem(
                  featuresEnum.ADMIN_TRANSFER_REQUESTS,
                  {
                    key: "AdminTransfers",
                    icon: <TransactionOutlined />,
                    label: <Link to="/transfers">{t("Transfers")}</Link>,
                  },
                  PermissionsEnum.ADM_REPORT_TRANSFER
                ),
                // ...addFeatureMenuItem(featuresEnum.ADMIN_LOAN_REQUESTS,
                {
                  key: "MandateManagment",
                  icon: <FileTextOutlined />,
                  label: (
                    <Link to="/mandate/selector">
                      {t("Mandate Management")}
                    </Link>
                  ),
                },
                // ),
                ...addFeatureMenuItem(featuresEnum.ADMIN_MANAGE_CHANNELS, {
                  key: "AdminGeneralRequests",
                  icon: <OrderedListOutlined />,
                  label: (
                    <Link to="/general-requests-selector">
                      {/* General Requests */}
                      {t("Manage Channels")}
                    </Link>
                  ),
                }),
                {
                  key: "AdminOrganisations",
                  icon: <ClusterOutlined />,
                  label: <Link to="/organisations">{t("Organisations")} </Link>,
                },
                ...addFeatureMenuItem(
                  featuresEnum.ADMIN_PRODUCT_CONFIGURATION,
                  {
                    key: "AdminProductConfiguration",
                    icon: <SettingOutlined />,
                    label: (
                      <Link to="/product-configuration">
                        {t("Product Configuration")}
                      </Link>
                    ),
                  }
                ),
              ]
            : [
                {
                  key: "dashboard",
                  icon: <HomeOutlined />,
                  label: <Link to="/dashboard">{t("Dashboard")}</Link>,
                },

                ...addFeatureMenuItem(featuresEnum.CUSTOMER_TRANSFER, {
                  key: "Transfer",
                  icon: <TransactionOutlined />,
                  label: <Link to="/transfer-type">{t("Transfer")}</Link>,
                }),
                ...addFeatureMenuItem(featuresEnum.CUSTOMER_AIRTIME_RECHARGE, {
                  key: "AirtimeRecharge",
                  icon: <PhoneOutlined />,
                  label: <Link to="/airtime">{t("Airtime Recharge")}</Link>,
                }),
                ...addFeatureMenuItem(featuresEnum.CUSTOMER_DATA_PURCHASE, {
                  key: "DataRecharge",
                  icon: <WifiOutlined />,
                  label: <Link to="/data">{t("Data Recharge")}</Link>,
                }),
                ...addFeatureMenuItem(featuresEnum.CUSTOMER_BILL, {
                  key: "BillsPayment",
                  icon: <MedicineBoxOutlined />,
                  label: <Link to="/bills-payment">{t("Bills Payment")}</Link>,
                }),
                ...addFeatureMenuItem(featuresEnum.CUSTOMER_LOAN, {
                  key: "loans",
                  icon: <DollarCircleOutlined />,
                  label: <Link to="/loan/types">{t("My Loans")}</Link>,
                }),

                {
                  key: "Request",
                  icon: <PullRequestOutlined />,
                  label: <Link to="/requests">{t("Requests")}</Link>,
                },
              ]),
          {
            key: "divider3",
            width: "auto",
            label: <Divider />,
            className: "non-interactive",
          },
          {
            key: "settings",
            icon: <SettingOutlined />,
            label: <Link to={settingsPath}>{t("Settings & More")}</Link>,
          },
          ...addFeatureMenuItem(featuresEnum.PORTAL_LANGUAGE_TOGGLE, {
            key: "english",
            icon: <GlobalOutlined />,
            label: (
              <div>
                <p
                  type="text"
                  onClick={showModal}
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {languageName}
                </p>
                <LanguageSwitcher />
              </div>
            ),
          }),
          ...addFeatureMenuItem(featuresEnum.PORTAL_DARK_MODE_SWITCH, {
            key: "theme",
            icon:
              theme === "dark" ? (
                <MoonOutlined onClick={toggleTheme} />
              ) : (
                <SunOutlined onClick={toggleTheme} />
              ),
            label: (
              <div className="flex items-center justify-between">
                <span className="mr-2">Dark Mode</span>
                <Switch
                  checked={theme === "dark"}
                  onChange={toggleTheme}
                  size="small"
                />
              </div>
            ),
          }),

          {
            key: "divider4",
            width: "auto",
            label: <Divider />,
            className: "non-interactive",
          },
          {
            key: "signout",
            icon: <LogoutOutlined />,
            label: "Sign Out",
            onClick: handleSignOut,
          },
        ]
      : []),
  ];

  return (
    <Menu
      onClick={({ key }) => onSelect(key)}
      selectedKeys={[selectedKey]}
      defaultSelectedKeys={["home", "dashboard"]}
      mode="inline"
      style={{
        height: "100vh",
        overflow: "auto",
      }}
      items={items}
    />
  );
};

export default Sidebar;

//
//
