import React, { createContext, useContext, useEffect, useState } from "react";
import { decryptData } from "../../utils/interceptors/localStorageService";
import { jwtDecode } from "jwt-decode";

// Create the context
const PermissionContext = createContext(null);

// Custom hook for accessing data context
export const usePermissions = () => {
  return useContext(PermissionContext);
};

// Provider component
export const PermissionProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null);
  console.log("this is from permission context");

  const updatePermissions = () => {
    const encryptedData = localStorage.getItem("sessionData");
    if (!!encryptedData) {
      const decryptedData = decryptData(encryptedData);
      const decodedToken = jwtDecode(decryptedData?.accessToken);
      const permissions = decodedToken?.Permission;
      setPermissions(permissions);
    } else {
      setPermissions(null);
    }
  };
  useEffect(() => {
    updatePermissions();
  }, []);

  const findPermission = (permissionValue) =>
    permissions?.includes(permissionValue);
  // true;

  // Return the provider with value
  return (
    <PermissionContext.Provider
      value={{ permissions, setPermissions, findPermission, updatePermissions }}
    >
      {children}
    </PermissionContext.Provider>
  );
};
