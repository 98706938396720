import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";
import { useApiRequests } from "../../../utils/api/https/executor";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const triggerNotification = useNotification();

  const [selectedUser, setSelectedUser] = useState(null);

  const [isAddUserRoleLoading, setIsAddUserRoleLoading] = useState(false);
  const [isAddUserBranchLoading, setIsAddUserBranchLoading] = useState(false);
  const [isRemoveUserBranchLoading, setIsRemoveUserBranchLoading] =
    useState(false);
  const [isRemoveUserRoleLoading, setIsRemoveUserRoleLoading] = useState(false);
  const [isPendingUsersLoading, setIsPendingUsersLoading] = useState(false);
  const [isVerifiedUsersLoading, setIsVerifiedUsersLoading] = useState(false);
  const [isSingleUserLoading, setIsSingleUserLoading] = useState(true);

  const [isChangingUserStatus, setIsChangingUserStatus] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 50,
    status: "",
    customerType: null,
    searchText: "",
    startDate: "",
    endDate: "",
  });
  const [verifiedUsers, setVerifiedUsers] = useState(null);
  const [pendingUsers, setPendingUsers] = useState(null);
  const [singleUserDetails, setSingleUserDetails] = useState(null);

  const [allUserRolesQuery, setAllUserRolesQuery] = useState(null);
  const [approvalInformation, setApprovalInformation] = useState(null);

  const [corporateProfile, setCorporateProfile] = useState(null);

  const [activeModal, setActiveModal] = useState(null);

  // const [] = useApiRequests();
  const apiRequests = useApiRequests();
  const openModal = (modalName) => {
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const fetchActiveUsers = async (data) => {
    setIsVerifiedUsersLoading(true); // Show loading indicator

    try {
      // Remove undefined or null values for roleId and branchId
      // const requestData = { ...data };
      // if (!requestData.roleId) delete requestData.roleId;
      // if (!requestData.branchId) delete requestData.branchId;

      const cleanedFilter = cleanObject(data);

      // Fetch verified users from the API
      const response = await apiRequests.corporateUsersVerifications(
        cleanedFilter
      );

      // Format the response
      const formattedUsers = response?.data?.map((user) => ({
        name: `${user.firstName} ${user.middleName || ""} ${
          user.lastName
        }`.trim(),
        phone: `${user.mobileCountryCode}${user.mobileNumber}`,
        ...user,
      }));

      // Update state with formatted users
      setVerifiedUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching pending users:", error);
      // Optionally, you can set an error state here if needed
    } finally {
      setIsVerifiedUsersLoading(false); // Always turn off loading indicator
    }
  };

  const fetchPendingUsers = async (data) => {
    setIsPendingUsersLoading(true); // Show loading indicator

    try {
      // Remove undefined or null values for roleId and branchId
      // const requestData = { ...data };
      // if (!requestData.roleId) delete requestData.roleId;
      // if (!requestData.branchId) delete requestData.branchId;
      const cleanedFilter = cleanObject(data);
      // Fetch pending users from the API
      const users = await apiRequests.corporateUsersPendingVerifications(
        cleanedFilter
      );

      // Format the response
      const formattedUsers = users.map((user) => ({
        name: `${user.firstName} ${user.middleName || ""} ${
          user.lastName
        }`.trim(),
        phone: `${user.mobileCountryCode}${user.mobileNumber}`,
        ...user,
      }));

      // Update state with formatted users
      setPendingUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching pending users:", error);
      // Optionally, you can set an error state here if needed
    } finally {
      setIsPendingUsersLoading(false); // Always turn off loading indicator
    }
  };

  const addUserToRole = async (data) => {
    try {
      setIsAddUserRoleLoading(true); // Start the loading state

      // Make API request to add the user to the role
      const response = await apiRequests.addUserToRole(data);

      if (response && response.outData) {
        return response.outData;
      } else {
        return [];
      }

      // Optional: Update state or UI with the new roles if needed
      // Example: setSelectedUserRoles(roles);
    } catch (error) {
      console.error("Error adding user to role:", error);
      // Handle the error (e.g., show a notification or alert)
    } finally {
      setIsAddUserRoleLoading(false); // Stop the loading state
    }
  };

  const addUserToBranch = async (data) => {
    setIsAddUserBranchLoading(true);
    // const data = { userId: selectedUser.id, branchId };
    const response = await apiRequests.addUserToBranch(data);

    setIsAddUserBranchLoading(false);

    if (response && response.outData) {
      return response.outData;
    } else {
      return [];
    }
  };

  const removeUserFromBranch = async (data) => {
    setIsRemoveUserBranchLoading(true); // Show loading indicator

    try {
      const response = await apiRequests.removeUserFromBranch(data);
      if (response && response.outData) {
        return response.outData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error removing user from branch:", error);
    } finally {
      setIsRemoveUserBranchLoading(false); // Hide loading indicator
    }
  };

  const removeUserFromRole = async (data) => {
    setIsRemoveUserRoleLoading(true); // Show loading indicator

    try {
      const response = await apiRequests.removeUserFromRole(data);
      if (response && response.outData) {
        return response.outData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error removing user from branch:", error);
    } finally {
      setIsRemoveUserRoleLoading(false); // Hide loading indicator
    }
  };

  const changeUserState = async (data) => {
    setIsChangingUserStatus(true); // Show loading indicator

    try {
      const response = await apiRequests.changeUserState(data);
      return response;
    } catch (error) {
      console.error("Error removing user from branch:", error);
    } finally {
      setIsChangingUserStatus(false); // Hide loading indicator
    }
  };

  const getUserDetails = useCallback(
    async (userId) => {
      try {
        setIsSingleUserLoading(true);
        const response = await apiRequests.corporateUsersVerifications({
          userId: Number(userId),
        });

        console.log("view user response", response);

        if (response?.isSuccessful) {
          setSingleUserDetails(response?.data[0]);
        } else {
          triggerNotification({
            type: "error",
            message: "Error",
            description: response?.message,
          });
        }
      } catch (error) {
        triggerNotification({
          type: "error",
          message: "Error",
          description: error?.message || "Failed to fetch user data",
        });
      } finally {
        setIsSingleUserLoading(false);
      }
    },
    [triggerNotification, apiRequests]
  );

  return (
    <UserContext.Provider
      value={{
        isPendingUsersLoading,
        isVerifiedUsersLoading,
        fetchPendingUsers,
        fetchActiveUsers,
        selectedUser,
        pendingUsers,
        verifiedUsers,
        changeUserState,
        addUserToRole,
        filter,
        setFilter,
        isAddUserBranchLoading,
        addUserToBranch,
        isRemoveUserBranchLoading,
        removeUserFromBranch,
        removeUserFromRole,
        isAddUserRoleLoading,
        corporateProfile,
        allUserRolesQuery,
        approvalInformation,
        isRemoveUserRoleLoading,
        isChangingUserStatus,
        setIsChangingUserStatus,
        activeModal,
        setSelectedUser,
        setActiveModal,
        openModal,
        closeModal,
        getUserDetails,
        isSingleUserLoading,
        singleUserDetails,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
