import { useEffect, useState } from "react";
import { Typography, Divider, Radio, Avatar, Button } from "antd";
import imgLogo from "../assets/images/dark-logo.png";
// import bgLogo from "../assets/images/authImage.jpg";
// import bgLogoDark from "../assets/images/authImageDark.png";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../store/context/theme-context";
import LanguageSelector from "../shared/languages/LanguageSelector";
import { CommentOutlined } from "@ant-design/icons";
import CountrySupportModal from "../shared/common/CountrySupportModal";
import { useTenant } from "../store/context/tenant-context";
import { useFeatures } from "../store/context/feature-context";
import { featuresEnum } from "../shared/constants/constants";

const { Title, Text } = Typography;

const InitialPage = () => {
  const navigate = useNavigate();
  const { findFeature } = useFeatures();

  const { theme } = useTheme();

  const [selectedOption, setSelectedOption] = useState(
    () => localStorage.getItem("accountType") || null
  );
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const sessionData = JSON.parse(localStorage.getItem("sessionData"));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const tenant = useTenant();
  // const [subdomain, setSubdomain] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleOkay = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    if (sessionData) {
      navigate("/home");
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sessionData, navigate]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleClick = (section) => {
    setSelectedOption(section);
    localStorage.setItem("accountType", section);
  };

  const handleContinue = () => {
    if (selectedOption === "Personal" || selectedOption === "Corporate") {
      navigate("/authentication?mode=signup");
    }
  };
  const handleClickMinScreen = (section) => {
    setSelectedOption(section);
    localStorage.setItem("accountType", section);
    navigate("/authentication?mode=signup");
  };

  return (
    <>
      {!collapsed && (
        <div className="flex flex-row w-full min-h-screen overflow-hidden">
          {/* Left Side */}
          <div
            className="relative flex flex-col w-3/5 min-h-screen bg-center bg-cover"
            style={{
              backgroundImage: `url(${
                theme === "dark"
                  ? tenant.loginImageBackgroundDark
                  : tenant.loginImageBackground
              })`,
              position: "fixed", // Fixed positioning for the left side
              top: 0, // Stick to the top of the viewport
              bottom: 0, // Take full height of the viewport
              left: 0, // Stick to the left side of the viewport
            }}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.location.replace("/");
              }}
            >
              <img
                src={theme === "dark" ? tenant.darkLogo : tenant.logo}
                alt="Logo"
                className="absolute top-0 left-0 h-20 mt-12 ml-16 w-30"
              />
            </a>
            <div className="mt-40 ml-16 text-left">
              <Title level={1} style={{ color: "white" }}>
                Welcome to <br />
                {tenant?.companyName}
              </Title>
              <Text className="text-[20px] text-white">{tenant?.tagLine}</Text>
            </div>
          </div>

          {/* Right Side */}
          <div
            className={`ml-[60%] w-2/5 min-h-screen overflow-y-auto ${
              theme === "dark" ? "bg-stone-900" : ""
            }`}
          >
            <div className="flex flex-col items-start justify-center p-8">
              <div className="text-left">
                <Title level={4} style={{ marginBottom: "5px" }}>
                  Hello There
                </Title>
                <Text
                  className="text-[#929497] text-[16px] font-normal"
                  style={{ display: "block", marginBottom: "30px" }}
                >
                  Please select an account option
                </Text>
              </div>

              <div className="w-full h-full min-w-[200px] max-w-[100%]">
                <Radio.Group
                  onChange={handleOptionChange}
                  value={selectedOption}
                  style={{ width: "100%" }}
                >
                  {findFeature(featuresEnum.CUSTOMER_SIGNUP_INDIVIDUAL) && (
                    <div
                      className="flex items-center justify-between w-full cursor-pointer"
                      onClick={() => handleClick("Personal")}
                    >
                      <div className="flex items-center justify-between min-w-[150px] w-full h-full">
                        <div className="flex flex-row items-center justify-center gap-1">
                          <Avatar
                            size={50}
                            style={{
                              cursor: "pointer",
                              marginRight: "10px",
                              backgroundColor: tenant.theme.colorPrimary,
                            }}
                          >
                            P
                          </Avatar>
                          <Text>Personal</Text>
                        </div>
                      </div>
                      <Radio value="Personal"></Radio>
                    </div>
                  )}
                  <Divider />
                  {findFeature(featuresEnum.CUSTOMER_SIGNUP_CORPORATE) && (
                    <div
                      className="flex items-center justify-between w-full cursor-pointer"
                      onClick={() => handleClick("Corporate")}
                    >
                      <div className="flex items-center justify-between min-w-[150px] w-full h-full">
                        <div className="flex flex-row items-center justify-center gap-1">
                          <Avatar
                            size={50}
                            style={{
                              cursor: "pointer",
                              marginRight: "10px",
                              backgroundColor: tenant.theme.colorPrimary,
                            }}
                          >
                            CS
                          </Avatar>
                          <Text>Corporate</Text>
                        </div>
                      </div>
                      <Radio value="Corporate"></Radio>
                    </div>
                  )}
                </Radio.Group>
              </div>
              {(findFeature(featuresEnum.CUSTOMER_SIGNUP_CORPORATE) ||
                findFeature(featuresEnum.CUSTOMER_SIGNUP_INDIVIDUAL)) && (
                <div className="mt-16">
                  <Button
                    type="primary"
                    onClick={handleContinue}
                    disabled={selectedOption === null}
                    className="h-full px-4 py-[6px] shadow-none text-center text-[16px]"
                  >
                    Continue
                  </Button>
                </div>
              )}
            </div>

            <div
              className={`fixed bottom-0  w-[40%] p-5 ${
                theme === "dark" ? "bg-stone-900 text-white" : "bg-[#fff]"
              }`}
            >
              <div className="flex flex-row items-center justify-between">
                {findFeature(featuresEnum.PORTAL_LANGUAGE_TOGGLE) && (
                  <div className="w-1/2">
                    <LanguageSelector />
                  </div>
                )}
                {findFeature(featuresEnum.PORTAL_SUPPORT_MODAL) && (
                  <div
                    className="flex items-center justify-center p-2 bg-gray-300 rounded-full cursor-pointer"
                    onClick={openModal}
                  >
                    <CommentOutlined
                      style={{ color: tenant.theme.colorPrimary }}
                      className="text-2xl"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {collapsed && (
        <div
          className="relative flex flex-col items-center w-full min-h-screen p-2 overflow-auto bg-center bg-cover"
          style={{
            backgroundImage: `url(${
              theme === "dark"
                ? tenant.loginImageBackgroundDark
                : tenant.loginImageBackground
            })`,
            position: "fixed", // Fixed positioning for the left side
            top: 0, // Stick to the top of the viewport
            bottom: 0, // Take full height of the viewport
            left: 0, // Stick to the left side of the viewport
            paddingBottom: "80px",
          }}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.location.replace("/");
            }}
          >
            <img
              src={theme === "dark" ? tenant.darkLogo : tenant.logo}
              alt="Logo"
              className="absolute h-20 mt-12 w-30"
            />
          </a>
          <div className="mt-40 text-center">
            <Title level={1} style={{ color: "white" }}>
              Welcome to {tenant?.companyName}
            </Title>
            <Text className="text-[20px] text-center text-white">
              {tenant?.tagLine}
            </Text>
            <br />
            <Text className="text-[16px] text-center text-white">
              Choose between personal and corporate accounts to continue
            </Text>
          </div>

          <div className="flex w-[60%] items-center justify-between mt-10 mx-auto">
            {findFeature(featuresEnum.PORTAL_LANGUAGE_TOGGLE) && (
              <div className="w-3/5 text-white">
                <LanguageSelector />
              </div>
            )}
            {findFeature(featuresEnum.PORTAL_SUPPORT_MODAL) && (
              <div
                className="flex items-center justify-center p-2 bg-gray-300 rounded-full cursor-pointer"
                onClick={openModal}
              >
                <CommentOutlined
                  style={{ color: tenant.theme.colorPrimary }}
                  className="text-2xl"
                />
              </div>
            )}
          </div>

          <div className="flex flex-col w-full gap-2 p-4 mt-36">
            <Button
              className={`h-full w-full px-5 py-2 shadow-none text-center text-lg bg-white text-[${tenant.theme.colorPrimary}]`}
              onClick={() => handleClickMinScreen("Personal")}
            >
              Personal
            </Button>
            <Button
              className={`h-full w-full px-5 py-2 shadow-none text-center text-lg bg-white text-[${tenant.theme.colorPrimary}]`}
              onClick={() => handleClickMinScreen("Corporate")}
            >
              Corporate
            </Button>
          </div>
        </div>
      )}

      <CountrySupportModal
        isModalOpen={isModalOpen}
        handleOkay={handleOkay}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default InitialPage;
