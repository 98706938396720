import React, { createContext, useContext, useEffect, useState } from "react";
import { postRequest } from "../../utils/api/requests/request";

// Create the context
const FeatureContext = createContext(null);

// Custom hook for accessing data context
export const useFeatures = () => {
  return useContext(FeatureContext);
};

// Provider component
export const FeatureProvider = ({ children }) => {
  const [features, setFeatures] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await postRequest("GetPlatformFeatureTogglesQuery"); // Replace with your API
        // console.log("response  --- ", response);
        if (response?.outData && response?.outData.isSuccessful) {
          setFeatures(response?.outData?.data);
        }
      } catch (err) {
        setError(err?.message);
      } finally {
        setLoading(false);
      }
    };
    if (!loading && !features) {
      fetchData();
    }
  }, []); // Run only on initial render

  const findFeature = (featureValue) =>
    features?.find((f) => f.id === featureValue)?.isEnabled ?? false;

  // Return the provider with value
  return (
    <FeatureContext.Provider value={{ features, findFeature, loading, error }}>
      {children}
    </FeatureContext.Provider>
  );
};
