import { theme } from "antd";

import empireLogo from "../../assets/tenants/empiretrust/logo.png";
import citygateLogo from "../../assets/tenants/citygate/logo.png";
import standardlinkLogo from "../../assets/tenants/standardlink/logo.png";
import defaultLogo from "../../assets/tenants/default/logo.png";

import empireDarkLogo from "../../assets/tenants/empiretrust/dark-logo.png";
import citygateDarkLogo from "../../assets/tenants/citygate/dark-logo.png";
import standardLinkDarkLogo from "../../assets/tenants/standardlink/dark-logo.png";
import defaultDarkLogo from "../../assets/tenants/default/dark-logo.png";

import empireImagebackground from "../../assets/tenants/empiretrust/authImage.jpg";
import citygateImagebackground from "../../assets/tenants/citygate/authImage.jpg";
import standardLinkImagebackground from "../../assets/tenants/standardlink/authImage.png";
import defaultImagebackground from "../../assets/tenants/default/authImage.jpg";

import empireImagebackgroundDark from "../../assets/tenants/empiretrust/authImageDark.png";
import citygateImagebackgroundDark from "../../assets/tenants/citygate/authImageDark.png";
import standarlinkImagebackgroundDark from "../../assets/tenants/standardlink/authImageDark.png";
import defaultImagebackgroundDark from "../../assets/tenants/default/authImageDark.png";

import empireAccountbgImage from "../../assets/tenants/empiretrust/profile_back_img.svg";
import cityAccountbgImage from "../../assets/tenants/citygate/profile_back_img.svg";
import defaultAccountbgImage from "../../assets/tenants/default/profile_back_img.svg";
import { getDomain, getSubdomain } from "../../utils/routes/baseURLRetriver";


const tenantAssets = {
  citygate: {
    companyName: "City Gate Investment",
    title: "Welcome to City Gate Investment",
    tagLine: "Your Gateway to Wealth Creation.",
    cssName: "citygate",
    theme: {
      colorPrimary: "#e5791b",
      primaryHoverBgColor: "#f9f9f9", // Hover background color for the button
      whiteBgColor: "#FFFFFF", // Background color when the button is selected
      textDefaultColor: "#2d3748", // Default text color for unselected state
      borderColor: "#d1d1d1", // Default border color for unselected state
      iconOutlineStyle: { color: "#e5791b", fontSize: "24px" },
      algorithm: theme.defaultAlgorithm,
    },
    profileBgImage: cityAccountbgImage,
    logo: citygateLogo,
    darkLogo: citygateDarkLogo,
    loginImageBackground: citygateImagebackground,
    loginImageBackgroundDark: citygateImagebackgroundDark,
    support: {
      mobile: "+234 123 456 7890",
      email: "support@banklingo.app",
      webSite: "www.banklingo.app",
      facebook: "https://www.facebook.com/banklingo",
    },
  },
  empiretrust: {
    companyName: "Empire Trust MFB",
    title: "Welcome to Empire Trust MFB",
    tagLine: "Enabling Success Together.",
    cssName: "empiretrust",
    profileBgImage: empireAccountbgImage,
    theme: {
      colorPrimary: "#e5791b",
      primaryHoverBgColor: "#f9f9f9", // Hover background color for the button
      whiteBgColor: "#FFFFFF", // Background color when the button is selected
      textDefaultColor: "#2d3748", // Default text color for unselected state
      borderColor: "#d1d1d1", // Default border color for unselected state
      iconOutlineStyle: { color: "#e5791b", fontSize: "24px" },
      algorithm: theme.darkAlgorithm,
    },
    logo: empireLogo,
    darkLogo: empireDarkLogo,
    loginImageBackground: empireImagebackground,
    loginImageBackgroundDark: empireImagebackgroundDark,
    support: {
      mobile: "+234 123 456 7890",
      email: "support@banklingo.app",
      webSite: "www.banklingo.app",
      facebook: "https://www.facebook.com/banklingo",
    },
  },
  standardlink: {
    companyName: "Standard Link",
    title: "Welcome to Standard Link",
    tagLine: "...linking you to greater height",
    cssName: "standardlink",
    profileBgImage: empireAccountbgImage,
    theme: {
      colorPrimary: "#03aa98",
      primaryHoverBgColor: "#f9f9f9", // Hover background color for the button
      whiteBgColor: "#FFFFFF", // Background color when the button is selected
      textDefaultColor: "#2d3748", // Default text color for unselected state
      borderColor: "#d1d1d1", // Default border color for unselected state
      iconOutlineStyle: { color: "#03aa98", fontSize: "24px" },
      algorithm: theme.darkAlgorithm,
    },
    logo: standardlinkLogo,
    darkLogo: standardLinkDarkLogo,
    loginImageBackground: standardLinkImagebackground,
    loginImageBackgroundDark: standarlinkImagebackgroundDark,
    support: {
      mobile: "+234 803 970 0147",
      email: "support@banklingo.app",
      webSite: "www.banklingo.app",
      facebook: "https://www.facebook.com/banklingo",
    },
  },
  default: {
    companyName: "Default Tenant",
    tagLine: "This is our development environment.",
    title: "Welcome to Default Tenant",
    cssName: "default",
    theme: {
      colorPrimary: `#008751`, // "#000000", the color code for Nigeria green is #008751
      primaryHoverBgColor: "#f9f9f9", // Hover background color for the button
      whiteBgColor: "#f9f9f9", // Background color when the button is selected
      textDefaultColor: "#2d3748", // Default text color for unselected state
      borderColor: "#d1d1d1", // Default border color for unselected state
      iconOutlineStyle: { color: "#008751", fontSize: "24px" },
      algorithm: theme.defaultAlgorithm,
    },
    logo: defaultLogo,
    profileBgImage: defaultAccountbgImage,
    darkLogo: defaultDarkLogo,
    loginImageBackground: defaultImagebackground,
    loginImageBackgroundDark: defaultImagebackgroundDark,
    support: {
      mobile: "+234 123 456 7890",
      email: "support@banklingo.app",
      webSite: "www.banklingo.app",
      facebook: "https://www.facebook.com/banklingo",
    },
  },
};
const getTenant = () => {
  const subdomain = getSubdomain();
  const domain = getDomain();

  // Map domains to tenants
  const domainToTenant = {
    "banklingo.standardlinkcontinental.com": "standardlink",
    "empiretrust.banklingo.app": "empiretrust",
    "citygate.banklingo.app": "citygate",
  };

  const tenantKey = tenantAssets[subdomain]
  ? subdomain
  : domainToTenant[domain] || "default";


  //  console.log("Subdomain:", subdomain);
  return tenantAssets[tenantKey] || tenantAssets.default; //empiretrust, citygate, default
};
export { getTenant };

export const getApiBaseUrl = () => {
  const hostname = window.location.hostname;

  // Map hostnames to API URLs
  const apiUrls = {
    "banklingo.standardlinkcontinental.com": "https://banklingoapi.standardlinkcontinental.com/Fintech.CBS.Backend",
    "empiretrust.example.com": "https://apitesting.borderlesspay.co.uk/Fintech.CBS.Backend",
    "citygate.example.com": "https://apitesting.borderlesspay.co.uk/Fintech.CBS.Backend",
    "default": "https://apitesting.borderlesspay.co.uk/Fintech.CBS.Backend", // Default API URL
  };

  // Return API URL based on hostname or default
  return apiUrls[hostname] || apiUrls["default"];
};

// WebSocket URL
export const getWebSocketUrl = () => {
  const hostname = window.location.hostname;

  const webSocketUrls = {
    "banklingo.standardlinkcontinental.com": "https://banklingoapi.standardlinkcontinental.com/Fintech.CBS.Backend/notificationHub",
    "empiretrust.example.com": "https://apitesting.borderlesspay.co.uk/Fintech.CBS.Backend/notificationHub",
    "citygate.example.com": "https://apitesting.borderlesspay.co.uk/Fintech.CBS.Backend/notificationHub",
    "default": "https://apitesting.borderlesspay.co.uk/Fintech.CBS.Backend/notificationHub",
  };

  return webSocketUrls[hostname] || webSocketUrls["default"];
};
