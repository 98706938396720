import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";
import { getSessionData } from "../../../utils/interceptors/localStorageService";

const AdminDashboardContext = createContext();

export const AdminDashboardProvider = ({ children }) => {
  const triggerNotification = useNotification();
  const [dashboardStats, setDashboardStats] = useState(null);
  const [isDashboardStatsLoading, setIsDashboardStatsLoading] = useState(false);
  const [savedSessionData, setSavedSessionData] = useState(null);

  const [filter, setFilter] = useState({
    reportPeriod: 5,
  });

  const getDashboardStats = useCallback(async () => {
    try {
      setIsDashboardStatsLoading(true);

      const response = await postAuthenticatedRequest(
        "GetAdminDashboardStatsQuery",
        { reportPeriod: filter.reportPeriod }
      );

      const result = response?.outData;

      console.log("dashboard stats", result);
      if (result?.isSuccessful) {
        setDashboardStats(result?.data);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsDashboardStatsLoading(false);
    }
  }, [triggerNotification, filter]);

  // Fetch session data from localStorage
  useEffect(() => {
    const storedSessionData = getSessionData();
    if (storedSessionData) {
      setSavedSessionData(storedSessionData);
    }
  }, []);
  return (
    <AdminDashboardContext.Provider
      value={{
        dashboardStats,
        getDashboardStats,
        isDashboardStatsLoading,
        filter,
        setFilter,
        savedSessionData,
      }}
    >
      {children}
    </AdminDashboardContext.Provider>
  );
};

export const useAdminDashboard = () => {
  return useContext(AdminDashboardContext);
};
