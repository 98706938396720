import React from "react";
import ReactDOM from "react-dom/client";

// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/redux/store";
import { pdfjs } from "react-pdf";
import { LanguageProvider } from "./store/context/language-context";
import { ThemeProvider } from "./store/context/theme-context";

// import { BrowserRouter } from 'react-router-dom';
import "./i18n";
import { FeatureProvider } from "./store/context/feature-context";
import { PermissionProvider } from "./store/context/permission-context";
import { getTenant } from "./shared/config/tenantConfiguration";
const tenant = getTenant();
import(`./assets/tenants/${tenant?.cssName}/index.css`);

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <LanguageProvider>
          <FeatureProvider>
            <PermissionProvider>
              <App />
            </PermissionProvider>
          </FeatureProvider>
        </LanguageProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
