import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";
import { downloadFileType } from "../../../shared/constants/constants";
import { downloadFile } from "../../../utils/helper/util";

const TransactionContext = createContext();

export const TransactionProvider = ({ children }) => {
  const [transactions, setTransactions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  const triggerNotification = useNotification();
  const [filter, setFilter] = useState({
    requestStatus: "",
    requestType: "",
    excludeTransfers: true,
    searchText: "",
    startDate: "",
    endDate: "",
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const getTransactions = useCallback(async () => {
    try {
      setIsLoading(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject({ ...filter, ...pagination });
      const response = await postAuthenticatedRequest(
        "GetTransferRequestsQuery",
        {
          operation: "GET",
          ...cleanedFilter,
        }
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        setTransactions(result);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch transactions",
      });
    } finally {
      setIsLoading(false);
    }
  }, [triggerNotification, filter, pagination]);

  // download transaction report
  const downloadTransactionReport = useCallback(async () => {
    try {
      setIsDownloadingReport(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject({
        ...filter,
        ...pagination,
        isExport: true,
        fileExportName: "TransactionReport",
        exportFields: {
          moduleType: "Module Type",
          serviceProviderCode: "Service Provider",
          transactionStatusDescription: "Status",
          transactionDate: "Transaction Date",
          requestId: "Transaction ID",
          transactionAmount: "Amount",
          sourceAccountNumber: "Source Account",
          serviceSubscriberReference: "Beneficiary",
          transactionRemarks: "Narration",
        },
      });
      const response = await postAuthenticatedRequest(
        "GetTransferRequestsQuery",
        {
          operation: "GET",
          ...cleanedFilter,
        }
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        downloadFile(
          result?.data,
          "Transaction_Report",
          downloadFileType.EXCEL
        );

        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "Transaction report downloaded",
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch transactions",
      });
    } finally {
      setIsDownloadingReport(false);
    }
  }, [triggerNotification, filter, pagination]);

  return (
    <TransactionContext.Provider
      value={{
        transactions,
        isLoading,
        isDownloadingReport,
        getTransactions,
        filter,
        setFilter,
        pagination,
        setPagination,
        downloadTransactionReport,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  return useContext(TransactionContext);
};
