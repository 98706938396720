import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNotification } from "../notification-context";
import { cleanObject } from "../../../utils/helper/format";
import { useApiRequests } from "../../../utils/api/https/executor";
import { downloadFile } from "../../../utils/helper/util";
import { downloadFileType } from "../../../shared/constants/constants";

const MandateContext = createContext();

export const MandateProvider = ({ children }) => {
  const triggerNotification = useNotification();

  const {
    createMandate,
    updateMandate,
    createMandateUpload,
    refreshMandateStatus,
    authoriseMandate,
    disapproveMandate,
    startMandate,
    collectionHistory,
    createMandateCollection,
    stopMandate,
    retrieveMandateBatches,
    retrieveMandates,
    retrieveCollectionBatches,
    retrieveMandateProductsQuery,
    retrieveMandatesQuery,
    retrieveMandateCollections,
    activateMandateCollection,
    cancelMandateCollection,
    deactivateMandateCollection,
    collectMandateFunds,
    startMandateCollection,
    stopMandateCollection,
    getMandateCollectionList,
    getMandateCollection,
  } = useApiRequests();

  // Granular loading states for different operations
  const [loadingStates, setLoadingStates] = useState({
    mandateCreation: false,
    createMandateUpload: false,
    mandateUpdate: false,
    createCollectionBatchUpload: false,
    mandateRetrievalDetails: false,
    mandateStatusRefresh: false,
    collectionHistoryRetrieval: false,
    mandateDisapproval: false,
    collectionRetrievalSingle: false,
    mandateStart: false,
    mandateStop: false,
    mandateAuthorization: false,
    mandateProductTypes: false,
    mandateBatchRetrieval: false,
    mandateRetrieval: false,
    downloadMandateRetrieval: false,

    collectionBatchRetrieval: false,
    collectionSingleBatchRetrieval: false,
    collectionActivation: false,
    collectionDeactivation: false,
    cancelCollection: false,
    collectCollection: false,
    startCollection: false,
    stopCollection: false,
    collectionRetrieval: false,
    mandateSingleRetrieval: false,
    downloadCollectionRetrieval: false,
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [globalError, setGlobalError] = useState(null);

  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 10,
    requestStatus: "",
    requestType: "",
    excludeTransfers: true,
    searchText: "",
    startDate: "",
    endDate: "",
  });
  // State for fetched data
  const [mandateBatches, setMandateBatches] = useState(null);
  const [mandates, setMandates] = useState(null);
  const [downloadMandates, setDownloadMandates] = useState(null);
  const [downloadMandateCollections, setDownloadMandateCollections] =
    useState(null);
  const [mandateSingle, setMandateSingle] = useState(null);
  const [collectionHistoryState, setCollectionHistoryState] = useState(null);
  const [collectionSingleBatch, setCollectionSingleBatch] = useState(null);
  const [collectionBatches, setCollectionBatches] = useState(null);
  const [mandateCollections, setMandateCollections] = useState(null);
  const [mandateCollectionSingle, setMandateCollectionSingle] = useState(null);
  const [mandateProductTypes, setMandateProductTypes] = useState(null);

  useEffect(() => {
    if (downloadMandates) {
      // process download
      // Convert Base64 string to a Blob
      const byteCharacters = atob(downloadMandates);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // Create a download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Mandate_Report.xlsx`; // File name with .xlsx extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [downloadMandates]);

  useEffect(() => {
    if (downloadMandateCollections) {
      console.log("downloadMandateCollections", downloadMandateCollections);
      // process download
      downloadFile(
        downloadMandateCollections,
        "Mandate_Collection_Report",
        downloadFileType.EXCEL
      );
    }
  }, [downloadMandateCollections]);

  // Updated handleApiCall with more flexible error and loading management
  const handleApiCall = async (
    apiCall,
    loadingStateKey,
    successMessage,
    errorMessage,
    setData = null,
    showSuccess = false
  ) => {
    try {
      setLoadingStates((prev) => ({ ...prev, [loadingStateKey]: true }));
      setGlobalError(null);

      const response = await apiCall();

      if (response.outData.isSuccessful) {
        // Save fetched data if a state setter is provided
        if (showSuccess) {
          triggerNotification({
            type: "success",
            message: "Success",
            description:
              response.outData.message || successMessage || "An error occurred",
          });
        }
        if (setData && response.outData?.data) {
          setData(response.outData.data);
        }

        return response;
      } else {
        // throw new Error("No response received");
        triggerNotification({
          type: "error",
          message: "Error",
          description:
            response.outData.message || errorMessage || "An error occurred",
        });
      }
    } catch (err) {
      setGlobalError(err);
      triggerNotification({
        type: "error",
        message: "Error",
        description: err.message || errorMessage || "An error occurred",
      });
      // throw err;
    } finally {
      setLoadingStates((prev) => ({ ...prev, [loadingStateKey]: false }));
    }
  };

  // Mandate Creation
  const handleMandateCreation = useCallback(
    async (mandateData) => {
      return handleApiCall(
        () => createMandate(cleanObject(mandateData)),
        "mandateCreation",
        "Mandate created successfully",
        "Failed to create mandate"
      );
    },
    [createMandate]
  );

  const handleUploadMandateCreation = useCallback(
    async (mandateData) => {
      return handleApiCall(
        () => createMandateUpload(cleanObject(mandateData)),
        "createMandateUpload",
        "Mandate created successfully",
        "Failed to create mandate"
      );
    },
    [createMandateUpload]
  );

  const handleUploadCollectionBatchCreation = useCallback(
    async (mandateData) => {
      return handleApiCall(
        () => createMandateCollection(cleanObject(mandateData)),
        "createCollectionBatchUpload",
        "Collections created successfully",
        "Failed to create batches"
      );
    },
    [createMandateCollection]
  );

  // Mandate Update
  const handleMandateUpdate = useCallback(
    async (mandateData) => {
      return handleApiCall(
        () => updateMandate(cleanObject(mandateData)),
        "mandateUpdate",
        "Mandate updated successfully",
        "Failed to update mandate"
      );
    },
    [updateMandate]
  );

  // Mandate Status Refresh
  const handleMandateStatusRefresh = useCallback(
    async (mandateId) => {
      return handleApiCall(
        () => refreshMandateStatus({ mandateId }),
        "mandateStatusRefresh",
        "Mandate status refreshed successfully",
        "Failed to refresh mandate status",
        null,
        true
      );
    },
    [refreshMandateStatus]
  );

  // Mandate Authorization
  const handleMandateAuthorization = useCallback(
    async (mandateId) => {
      return handleApiCall(
        () => authoriseMandate({ mandateId }),
        "mandateAuthorization",
        "Mandate authorized successfully",
        "Failed to authorize mandate",
        null,
        true
      );
    },
    [authoriseMandate]
  );

  // Collection Activate
  const handleCollectionActivation = useCallback(
    async (batchId) => {
      return handleApiCall(
        () => activateMandateCollection({ batchId }),
        "collectionActivation",
        "Collection activated successfully",
        "Failed to active collection"
      );
    },
    [activateMandateCollection]
  );

  // cancel Collection
  const handleCollectionCancellation = useCallback(
    async (batchId) => {
      return handleApiCall(
        () => cancelMandateCollection({ batchId }),
        "cancelCollection",
        "Collection cancelled successfully",
        "Failed to cancel collection"
      );
    },
    [cancelMandateCollection]
  );

  // Deactivate Collection
  const handleCollectionDeactivataion = useCallback(
    async (batchId) => {
      return handleApiCall(
        () => deactivateMandateCollection({ batchId }),
        "collectionDeactivation",
        "Collection deactivate successfully",
        "Failed to deactivate collection",
        null,
        true
      );
    },
    [deactivateMandateCollection]
  );

  // Collect Collection
  const handleCollectCollection = useCallback(
    async (data) => {
      return handleApiCall(
        () => collectMandateFunds(data),
        "collectCollection",
        "Collection collected successfully",
        "Failed to collect collection",
        null,
        true
      );
    },
    [collectMandateFunds]
  );

  // Deactivate Collection
  const handleStartMandateCollection = useCallback(
    async (batchId) => {
      return handleApiCall(
        () => startMandateCollection({ batchId }),
        "startCollection",
        "Start Collection initiated successfully",
        "Failed to start collection",
        null,
        true
      );
    },
    [startMandateCollection]
  );

  // Deactivate Collection
  const handleStopMandateCollection = useCallback(
    async (batchId) => {
      return handleApiCall(
        () => stopMandateCollection({ batchId }),
        "stopCollection",
        "Stop Collection initiated successfully",
        "Failed to stop collection",
        null,
        true
      );
    },
    [stopMandateCollection]
  );

  // Mandate Authorization
  const handleMandateStart = useCallback(
    async (mandateId) => {
      return handleApiCall(
        () => startMandate({ mandateId }),
        "mandateStart",
        "Mandate start successfully",
        "Failed to stop mandate",
        null,
        true
      );
    },
    [startMandate]
  );

  // Mandate Authorization
  const handleMandateStop = useCallback(
    async (mandateId) => {
      return handleApiCall(
        () => stopMandate({ mandateId }),
        "mandateStop",
        "Mandate stop successfully",
        "Failed to stop mandate",
        null,
        true
      );
    },
    [stopMandate]
  );

  // Mandate Authorization
  const handleMandateDisapproval = useCallback(
    async (mandateId) => {
      return handleApiCall(
        () => disapproveMandate({ mandateId }),
        "mandateDisapproval",
        "Mandate declined successfully",
        "Failed to decline mandate",
        null,
        true
      );
    },
    [disapproveMandate]
  );

  // Updated functions with state setters
  const fetchMandateBatches = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveMandateBatches(cleanObject(filters)),
        "mandateBatchRetrieval",
        "Mandate batches retrieved successfully",
        "Failed to retrieve mandate batches",
        setMandateBatches
      );
    },
    [retrieveMandateBatches]
  );

  const fetchCollectionHistory = useCallback(
    async (filters) => {
      return handleApiCall(
        () => collectionHistory(cleanObject(filters)),
        "collectionHistoryRetrieval",
        "Mandate batches retrieved successfully",
        "Failed to retrieve mandate batches",
        setCollectionHistoryState
      );
    },
    [retrieveMandateBatches]
  );

  const fetchMandates = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveMandates(cleanObject(filters)),
        "mandateRetrieval",
        "Mandates retrieved successfully",
        "Failed to retrieve mandates",
        setMandates
      );
    },
    [retrieveMandates]
  );

  const downloadMandateCall = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveMandates(cleanObject(filters)),
        "downloadMandateRetrieval",
        "Mandates downloaded successfully",
        "Failed to download mandates",
        setDownloadMandates
      );
    },
    [retrieveMandates]
  );

  const fetchSingleMandate = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveMandates(cleanObject(filters)),
        "mandateSingleRetrieval",
        "Mandate retrieved successfully",
        "Failed to retrieve mandates with provided ID",
        setMandateSingle
      );
    },
    [retrieveMandates]
  );

  const fetchMandatesDetail = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveMandatesQuery(cleanObject(filters)),
        "mandateRetrievalDetails",
        "Mandate Query retrieved successfully",
        "Failed to retrieve mandate",
        setMandates
      );
    },
    [retrieveMandatesQuery]
  );

  const fetchCollectionBatches = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveCollectionBatches(cleanObject(filters)),
        "collectionBatchRetrieval",
        "Collection batches retrieved successfully",
        "Failed to retrieve collection batches",
        setCollectionBatches
      );
    },
    [retrieveCollectionBatches]
  );

  const fetchCollectionSingleBatch = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveCollectionBatches(cleanObject(filters)),
        "collectionSingleBatchRetrieval",
        "Collection batch retrieved successfully",
        "Failed to retrieve collection batch",
        setCollectionSingleBatch
      );
    },
    [retrieveCollectionBatches]
  );

  const fetchMandateCollections = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveMandateCollections(cleanObject(filters)),
        "collectionRetrieval",
        "Mandate collections retrieved successfully",
        "Failed to retrieve mandate collections",
        setMandateCollections
      );
    },
    [retrieveMandateCollections]
  );

  const fetchMandateCollectionSingle = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveMandateCollections(cleanObject(filters)),
        "collectionRetrievalSingle",
        "Mandate collection retrieved successfully",
        "Failed to retrieve mandate collection",
        setMandateCollectionSingle
      );
    },
    [retrieveMandateCollections]
  );

  const downloadMandateCollectionsCall = useCallback(
    async (filters) => {
      return handleApiCall(
        () => retrieveMandateCollections(cleanObject(filters)),
        "downloadCollectionRetrieval",
        "Mandate collections downloaded successfully",
        "Failed to download mandate collections",
        setDownloadMandateCollections
      );
    },
    [retrieveMandateCollections]
  );

  const fetchMandateProductTypes = useCallback(async () => {
    return handleApiCall(
      () => retrieveMandateProductsQuery(),
      "mandateProductTypes",
      "Mandate products retrieved successfully",
      "Failed to retrieve mandate products",
      setMandateProductTypes
    );
  }, [retrieveMandateProductsQuery]);

  return (
    <MandateContext.Provider
      value={{
        // Mandate Operations
        handleMandateCreation,
        handleUploadMandateCreation,
        handleUploadCollectionBatchCreation,
        handleMandateUpdate,
        handleMandateStatusRefresh,
        handleMandateAuthorization,
        handleMandateDisapproval,
        handleMandateStart,
        handleMandateStop,
        handleCollectionActivation,
        handleCollectionCancellation,
        handleCollectionDeactivataion,
        handleCollectCollection,
        handleStartMandateCollection,
        stopMandateCollection,

        // Retrieval Operations
        fetchMandateBatches,
        fetchMandateCollectionSingle,
        fetchMandateProductTypes,
        fetchMandates,
        fetchCollectionBatches,
        fetchMandateCollections,
        fetchCollectionHistory,
        fetchSingleMandate,
        fetchMandatesDetail,
        fetchCollectionSingleBatch,
        downloadMandateCall,
        downloadMandateCollectionsCall,
        // Loading States
        loadingStates,

        // Global Error
        globalError,

        // data
        mandateBatches,
        mandateSingle,
        collectionHistoryState,
        mandateCollectionSingle,
        filter,
        collectionSingleBatch,
        setFilter,
        mandates,
        collectionBatches,
        mandateCollections,
        mandateProductTypes,
        pagination,
        setPagination,
      }}
    >
      {children}
    </MandateContext.Provider>
  );
};

export const useMandate = () => {
  const context = useContext(MandateContext);
  if (!context) {
    throw new Error("useMandate must be used within a MandateProvider");
  }
  return context;
};

export default MandateProvider;
