import { createContext, useContext } from "react";
import { useLanguage } from "../../../store/context/language-context";
import { productData } from "../../data/jsonData";
import { postRequest, postAuthenticatedRequest } from "../requests/request";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const executeRequest = async (command, data) => {
    return postRequest(command, data);
  };

  const executeAuthenticatedRequest = async (command, data) => {
    return postAuthenticatedRequest(command, data);
  };

  // Function to create a self-service loan product
  const createSelfServiceLoanProduct = async (data) => {
    try {
      const command = "CreateSelfServiceLoanProductCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // Function to update a self-service loan product
  const updateSelfServiceLoanProduct = async (data) => {
    try {
      const command = "UpdateSelfServiceLoanProductCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const resendVerificationLink = async (data) =>
    executeAuthenticatedRequest("ResendUnverifiedUserLinkCommand", data);

  // Function to create an organisation
  const createOrganisation = async (data) => {
    try {
      const command = "CreateOrganisationCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // Function to update a self-service organisation
  const updateSelfServiceOrganisation = async (data) => {
    try {
      const command = "UpdateSelfServiceOrganisationCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // Function to create a division
  const createDivision = async (data) => {
    try {
      const command = "CreateDivisionCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // Function to update a self-service division
  const updateSelfServiceDivision = async (data) => {
    try {
      const command = "UpdateSelfServiceDivisionCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // Function to create an area
  const createArea = async (data) => {
    try {
      const command = "CreateAreaCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // Function to update a self-service area
  const updateSelfServiceArea = async (data) => {
    try {
      const command = "UpdateSelfServiceAreaCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  // Airtime
  const airtimeRequest = async (data) => {
    try {
      const command = "AirtimeRechargeCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  // bills
  const billsRequest = async (data) => {
    try {
      const command = "BillsPaymentCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  // Download Transaction Receipt
  const downloadTransactionReceipt = async (data) => {
    try {
      const command = "DownloadTransactionReceiptCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error downloading transaction receipt:", error);
      return [];
    }
  };

  //  Save beneficiary
  const saveBeneficiary = async (data) => {
    try {
      const command = "SaveBeneficiaryCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error saving beneficiary:", error);
      return [];
    }
  };

  //  Remove beneficiary
  const removeBeneficiary = async (data) => {
    try {
      const command = "RemoveBeneficiaryCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error removing beneficiary:", error);
      return [];
    }
  };

  const changeUserState = async (data) => {
    try {
      const command = "ChangeCorporateUserStateCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const createUser = async (data) => {
    try {
      const command = "PreAdminUserRegistrationCommand"; // "PreCoorporateUserRegistrationCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const createCorporateUser = async (data) => {
    try {
      const command = "PreCoorporateUserRegistrationCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? response ?? {};
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const dataRequest = async (data) => {
    try {
      const command = "DataRechargeCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const fetchBankList = async () => {
    try {
      const command = "BankListQuery";
      const response = await executeRequest(command, {});
      console.log("Bank List:", response);
      return response;
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const requestAccountReactivation = async (data) => {
    try {
      const command = "AccountReactivationRequestCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const requestContactUpdate = async (data) => {
    try {
      let command = "";
      if (data.contactType == "phone") {
        delete data.emailAddress;
        command = "ContactMobileUpdateRequestCommand";
      } else {
        delete data.mobileNumber;
        command = "ContactEmailUpdateRequestCommand";
      }
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };
  //

  const requestPINResetInitiate = async (data) => {
    try {
      const command = "InitiatePinResetCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  //

  const requestAccountStatement = async (data) => {
    try {
      const command = "StatementRequestCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const transferRequest = async (data) => {
    try {
      const command = "TransferCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const updateCorporateUser = async (data) => {
    try {
      const command = "UpdateCorporateUserCommand";
      const response = await executeAuthenticatedRequest(command, data);
      return response?.outData ?? [];
    } catch (error) {
      console.error("Error editing user:", error);
      return [];
    }
  };

  const fetchCorporateUsers = async (data) => {
    try {
      const command = "RetrieveCorporateProfileUsersQuery";
      const response = await executeAuthenticatedRequest(command, data);
      if (response && response.outData.data) {
        return response.outData.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const fetchPaymentData = async () => {
    try {
      const command = "GetInsurancePaymentsQuery";
      const data = { operation: "POST" };

      const response = await executeAuthenticatedRequest(command, data);

      if (response && response.outData.data) {
        // console.log(response.outData.data);
        return response.outData.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching policies:", error);
      return [];
    }
  };

  const corporateUsersVerifications = async (data) => {
    try {
      const command = "RetrieveSelfServiceUsersQuery"; // "RetrieveCorporatePendingVerificationsQuery";

      const response = await executeAuthenticatedRequest(command, data);

      if (response && response.outData) {
        return response.outData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const corporateUsersPendingVerifications = async (data) => {
    try {
      const command = "RetrieveCorporatePendingVerificationsQuery";

      const response = await executeAuthenticatedRequest(command, data);

      if (response && response.outData.data) {
        return response.outData.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const roleUsersPendingVerifications = async (data) => {
    try {
      const command = "GetAllRoleUsersQuery"; // "RetrieveCorporatePendingVerificationsQuery";

      const response = await executeAuthenticatedRequest(command, data);

      if (response && response.outData.data) {
        return response.outData.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const branchUsersPendingVerifications = async (data) => {
    try {
      const command = "GetAllBranchUsersQuery"; // "RetrieveCorporatePendingVerificationsQuery";

      const response = await executeAuthenticatedRequest(command, data);

      if (response && response.outData.data) {
        return response.outData.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const getAllUserRolesQuery = async (data) => {
    try {
      const command = "GetAllUserRolesQuery"; // "RetrieveCorporatePendingVerificationsQuery";

      const response = await executeAuthenticatedRequest(command, data);

      if (response && response.outData.data) {
        return response.outData.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  //

  const fetchPoliciesData = async () => {
    try {
      const command = "GetBankingProfileQuery";
      const data = { operation: "POST" };

      const response = await executeAuthenticatedRequest(command, data);

      if (response && response.outData.data) {
        // console.log(response.outData.data);
        return response.outData.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching policies:", error);
      return [];
    }
  };

  const linkValidation = async (data) => {
    try {
      const command = "ValidateLinkCommand";

      const response = await executeAuthenticatedRequest(command, data);

      // console.log("API Response:", response);

      if (response && response.outData && response.outData.isSuccessful) {
        return response.outData;
      } else {
        return {
          isSuccessful: false,
          message: response?.message || "Unknown error",
        };
      }
    } catch (error) {
      console.error("Error validating temporary link:", error);
      return {
        isSuccessful: false,
        message: "Error occurred during validation",
      };
    }
  };

  const userCreateRequest = async (data) => {
    try {
      const command = "CreateQuoteUserDetailCommand";

      const response = await postRequest(command, data);

      if (response && response.outData) {
        return response.outData;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  const loanCreateRequest = async (formData, productName, quoteUserId) => {
    try {
      const Duration = ["Days", "Weeks", "Months", "Years"];

      let data = {
        loanAmount: formData.loanAmount,
        // quoteUserId,
        loanTenor: formData.repaymentPeriod,
        loanTenorType: Duration.indexOf(formData.duration),
        guarantors: [
          {
            name: formData.Guarantor1Name,
            emailaddress: formData.Guarantor1Email,
            mobileNumber: formData.Guarantor1Mobile,
            relationship: formData.Guarantor1Relationship,
            address: formData.Guarantor1Address,
          },
          {
            name: formData.Guarantor2Name,
            emailaddress: formData.Guarantor2Email,
            mobileNumber: formData.Guarantor2Mobile,
            relationship: formData.Guarantor2Relationship,
            address: formData.Guarantor2Address,
          },
          {
            name: formData.Guarantor3Name,
            emailaddress: formData.Guarantor33Email,
            mobileNumber: formData.Guarantor3Mobile,
            relationship: formData.Guarantor3Relationship,
            address: formData.Guarantor3Address,
          },
        ],
        nextOfKin: {
          name: formData.nextOfKinName,
          emailaddress: formData.nextOfKinEmail,
          mobileNumber: `${formData.nextOfKinPhoneArea}${formData.nextOfKinNumber}`,
          relationship: formData.relationship,
          address: formData.nextOfKinAddress,
        },
        employmentInformation: {
          employmentType: formData.employmentType,
          employeeNumber: formData.employeeNumber,
          employerName: formData.employer,
          employerAddress: formData.employerAddress,
          salaryaccountnumber: formData.accountNumber,
          salaryBankCode: formData.bankCode,
          officialemailaddress: formData.officialEmail,
          salaryPaymentDay: formData.salaryDate,
          employmentStartDate: formData.appointmentDate,
        },
      };

      if (productName.toLowerCase().includes("payroll")) {
        delete data.guarantors;
      }

      if (quoteUserId) {
        data.quoteUserId = quoteUserId;
      } else {
        console.warn("quoteUserId is not defined or empty");
      }

      // Find the first matching product, set its code, or default to "000p45"
      const matchingProducts = productData.filter((e) =>
        e.name?.toLowerCase().includes(productName.toLowerCase())
      );

      data.productCode =
        matchingProducts.length > 0 ? matchingProducts[0].code : "000p45";

      const command = "CreateSelfServiceLoanRequestCommand";

      let response;
      if (quoteUserId) {
        response = await postRequest(command, data);
      } else {
        response = await executeAuthenticatedRequest(command, data);
      }

      if (response && response.outData.data) {
        return response.outData.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error creating new user:", error);
      return [];
    }
  };

  // Additional API requests
  const preRegisterUser = (data) =>
    executeRequest("PreRegistrationCommand", data);
  const validateOTP = (data) => executeRequest("ValidateOtpCommand", data);
  const preRegisterCorporateUser = (data) =>
    executeRequest("PreCoorporateRegistrationCommand", data);
  const resendOTP = (data) => executeRequest("ResendOtpCommand", data);
  const completeRegistration = (data) =>
    executeRequest("CompleteRegistrationCommand", data);
  const getUserProfileQuery = (data) =>
    executeAuthenticatedRequest("UserProfileQuery", data);
  const loginUser = (data) => executeRequest("SelfLoginCommand", data);
  const loginAdmin = (data) => executeRequest("SelfAdminLoginCommand", data);
  const initiateResetPassword = (data) =>
    executeRequest("InitiatePasswordResetCommand", data);
  const completePasswordReset = (data) =>
    executeRequest("CompletePasswordResetCommand", data);
  const securityQuestionQuery = (data) =>
    executeRequest("SecurityQuestionQuery", data);
  const validateBankAccountNumber = (data) =>
    executeRequest("ValidateBankAccountNumberCommand", data);

  const updateUserProfile = (data) =>
    executeAuthenticatedRequest("UpdateUserProfileCommand", data);
  const getUserDetails = (data) =>
    executeAuthenticatedRequest("GetUserDetailsCommand", data);
  const changePassword = (data) =>
    executeAuthenticatedRequest("ChangePasswordCommand", data);
  const initiateUpdateSecurityQuestionCommand = (data) =>
    executeAuthenticatedRequest("InitiateUpdateSecurityQuestionCommand", data);
  const updateSecurityQuestionCommand = (data) =>
    executeAuthenticatedRequest("UpdateSecurityQuestionCommand", data);

  const addUserToRole = (data) =>
    executeAuthenticatedRequest("AddUserToRoleCommand", data);

  const addUserToBranch = (data) =>
    executeAuthenticatedRequest("AddUserToBranchCommand", data);

  const removeUserFromBranch = (data) =>
    executeAuthenticatedRequest("RemoveUserFromBranchCommand", data);
  const removeUserFromRole = (data) =>
    executeAuthenticatedRequest("RemoveUserFromRoleCommand", data);
  const removeUnverifiedUser = (data) =>
    executeAuthenticatedRequest("RemoveUnverifiedUserCommand", data);

  /**
   * Creates a new mandate
   * @param {Object} data Mandate creation parameters
   * @returns {Promise} Promise resolving with mandate creation response
   */
  const createMandate = (data) =>
    executeAuthenticatedRequest("CreateMandateCommand", data);

  const createMandateUpload = (data) =>
    executeAuthenticatedRequest("CreateMandateListCommand", data);

  const activateMandateCollection = (data) =>
    executeAuthenticatedRequest("ActivateMandateCollectionCommand", data);

  const cancelMandateCollection = (data) =>
    executeAuthenticatedRequest("CancelMandateCollectionCommand", data);

  const deactivateMandateCollection = (data) =>
    executeAuthenticatedRequest("DeactivateMandateCollectionCommand", data);

  const collectMandateFunds = (data) =>
    executeAuthenticatedRequest(
      "CollectMandateFundsForCollectionCommand",
      data
    );

  const collectionHistory = (data) =>
    executeAuthenticatedRequest(
      "RetrieveMandateCollectionTransactionsQuery",
      data
    );
  
  const startMandateCollection = (data) =>
    executeAuthenticatedRequest("RunCollectionBatchCommand", data);

  const stopMandateCollection = (data) =>
    executeAuthenticatedRequest("StopMandateCollectionCommand", data);

  const getMandateCollectionList = (data) =>
    executeAuthenticatedRequest("GetMandateCollectionListQuery", data);

  const getMandateCollection = (data) =>
    executeAuthenticatedRequest("GetMandateCollectionQuery", data);

  const createMandateCollection = (data) =>
    executeAuthenticatedRequest("CreateMandateCollectionCommand", data);

  /**
   * Updates an existing mandate
   * @param {Object} data Mandate update parameters
   * @returns {Promise} Promise resolving with mandate update response
   */
  const updateMandate = (data) =>
    executeAuthenticatedRequest("UpdateMandateCommand", data);

  /**
   * Refreshes the status of a specific mandate
   * @param {Object} data Mandate status refresh parameters
   * @returns {Promise} Promise resolving with mandate status refresh response
   */
  const refreshMandateStatus = (data) =>
    executeAuthenticatedRequest("RefreshMandateStatusCommand", data);

  /**
   * Authorises a specific mandate
   * @param {Object} data Mandate authorisation parameters
   * @returns {Promise} Promise resolving with mandate authorisation response
   */
  const authoriseMandate = (data) =>
    executeAuthenticatedRequest("AuthoriseMandateCommand", data);

  /**
   * Starts a specific mandate
   * @param {Object} data Mandate authorisation parameters
   * @returns {Promise} Promise resolving with mandate authorisation response
   */
  const startMandate = (data) =>
    executeAuthenticatedRequest("StartMandateCommand", data);

  /**
   * Starts a specific mandate
   * @param {Object} data Mandate authorisation parameters
   * @returns {Promise} Promise resolving with mandate stops response
   */
  const stopMandate = (data) =>
    executeAuthenticatedRequest("StopMandateCommand", data);

  /**
   * Declines a specific mandate
   * @param {Object} data Mandate authorisation parameters
   * @returns {Promise} Promise resolving with mandate disapproval response
   */
  const disapproveMandate = (data) =>
    executeAuthenticatedRequest("DisapproveMandateCommand", data);

  /**
   * Authorises a specific mandate
   * @returns {Promise} Promise resolving with mandate product types
   */
  const retrieveMandateProductsQuery = () =>
    executeAuthenticatedRequest("RetrieveMandateProductsQuery", {});

  /**
   * Retrieves mandate batches with optional filtering and pagination
   * @param {Object} data Mandate batch retrieval parameters
   * @returns {Promise} Promise resolving with mandate batches
   */
  const retrieveMandateBatches = (data) =>
    executeAuthenticatedRequest("RetrieveMandateBatchesQuery", data);

  /**
   * Retrieves mandates with optional filtering and pagination
   * @param {Object} data Mandate retrieval parameters
   * @returns {Promise} Promise resolving with mandates
   */
  const retrieveMandates = (data) =>
    executeAuthenticatedRequest("RetrieveMandatesQuery", data);

  /**
   * Retrieves mandates with optional filtering and pagination
   * @param {Object} data Mandate Query retrieval parameters
   * @returns {Promise} Promise resolving with mandates
   */
  const retrieveMandatesQuery = (data) =>
    executeAuthenticatedRequest("GetMandateCollectionListQuery", data);

  // Mandate Collection Command Executors

  /**
   * Retrieves mandate collection batches with optional filtering and pagination
   * @param {Object} data Collection batch retrieval parameters
   * @returns {Promise} Promise resolving with collection batches
   */
  const retrieveCollectionBatches = (data) =>
    executeAuthenticatedRequest("RetrieveCollectionBatchesQuery", data);

  /**
   * Retrieves mandate collections with optional filtering and pagination
   * @param {Object} data Mandate collection retrieval parameters
   * @returns {Promise} Promise resolving with mandate collections
   */
  const retrieveMandateCollections = (data) =>
    executeAuthenticatedRequest("RetrieveMandateCollectionsQuery", data);

  return (
    <ApiContext.Provider
      value={{
        createSelfServiceLoanProduct,
        updateSelfServiceLoanProduct,
        createOrganisation,
        updateSelfServiceOrganisation,
        createDivision,
        updateSelfServiceDivision,
        createArea,
        updateSelfServiceArea,
        preRegisterUser,
        retrieveMandatesQuery,
        validateOTP,
        getUserProfileQuery,
        preRegisterCorporateUser,
        resendOTP,
        branchUsersPendingVerifications,
        roleUsersPendingVerifications,
        completeRegistration,
        loginUser,
        loginAdmin,
        removeUserFromBranch,
        removeUserFromRole,
        removeUnverifiedUser,
        collectionHistory,
        initiateResetPassword,
        resendVerificationLink,
        completePasswordReset,
        securityQuestionQuery,
        createMandateCollection,
        updateUserProfile,
        addUserToRole,
        startMandate,
        stopMandate,
        getUserDetails,
        changePassword,
        retrieveMandateProductsQuery,
        initiateUpdateSecurityQuestionCommand,
        updateSecurityQuestionCommand,
        airtimeRequest,
        addUserToBranch,
        billsRequest,
        downloadTransactionReceipt,
        saveBeneficiary,
        removeBeneficiary,
        requestAccountStatement,
        changeUserState,
        transferRequest,
        corporateUsersVerifications,
        createCorporateUser,
        dataRequest,
        updateCorporateUser,
        fetchCorporateUsers,
        fetchBankList,
        requestContactUpdate,
        getAllUserRolesQuery,
        corporateUsersPendingVerifications,
        requestAccountReactivation,
        fetchPaymentData,
        createUser,
        fetchPoliciesData,
        linkValidation,
        validateBankAccountNumber,
        requestPINResetInitiate,
        userCreateRequest,
        loanCreateRequest,
        createMandate,
        createMandateUpload,
        updateMandate,
        refreshMandateStatus,
        authoriseMandate,
        disapproveMandate,
        retrieveMandateBatches,
        retrieveMandates,
        retrieveCollectionBatches,
        retrieveMandateCollections,
        activateMandateCollection,
        cancelMandateCollection,
        deactivateMandateCollection,
        collectMandateFunds,
        startMandateCollection,
        stopMandateCollection,
        getMandateCollectionList,
        getMandateCollection,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApiRequests = () => {
  return useContext(ApiContext);
};
