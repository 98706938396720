import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";
import { downloadFileType } from "../../../shared/constants/constants";
import { downloadFile } from "../../../utils/helper/util";

const LoanRequestContext = createContext();

export const LoanRequestProvider = ({ children }) => {
  const triggerNotification = useNotification();
  const [loanRequests, setLoanRequests] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const [filter, setFilter] = useState({
    status: "",
    productId: "",
    searchText: "",
    startDate: "",
    endDate: "",
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const getLoanRequests = useCallback(async () => {
    try {
      setIsLoading(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject({ ...filter, ...pagination });

      console.log("cleanedFilter from loan request", cleanedFilter);

      const response = await postAuthenticatedRequest(
        "GetLoanQuotesWithFiltersQuery",
        cleanedFilter
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        console.log("loan requests is", result?.data);
        setLoanRequests(result);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsLoading(false);
    }
  }, [triggerNotification, filter, pagination]);

  // download loan request report
  const downloadLoanRequestReport = useCallback(async () => {
    try {
      setIsDownloadingReport(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject({
        ...filter,
        ...pagination,
        isExport: true,
        fileExportName: "LoanRequestReport",
        exportFields: {
          displayName: "Display Name",
          productName: "Product Name",
          branchName: "Branch Name",
          loanTenor: "Tenor",
          loanTenorTypeDescription: "Tenor Description",
          currencyCode: "currency",
          loanAmount: "Amount",
          approvalStateDesc: "Status",
          interestRateDisplay: "Interest",
          createdAt: "Application Date",
        },
      });

      const response = await postAuthenticatedRequest(
        "GetLoanQuotesWithFiltersQuery",
        cleanedFilter
      );

      const result = response?.outData;
      console.log("result from download loan requests");
      if (result?.isSuccessful) {
        downloadFile(
          result?.data,
          "Loan_Request_Report",
          downloadFileType.EXCEL
        );
        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "Loan request report downloaded",
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsDownloadingReport(false);
    }
  }, [triggerNotification, filter, pagination]);

  return (
    <LoanRequestContext.Provider
      value={{
        loanRequests,
        isLoading,
        isDownloadingReport,
        getLoanRequests,
        downloadLoanRequestReport,
        filter,
        setFilter,
        pagination,
        setPagination,
      }}
    >
      {children}
    </LoanRequestContext.Provider>
  );
};

export const useLoanRequest = () => {
  return useContext(LoanRequestContext);
};
