import React, { useContext, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  notification,
  Dropdown,
  Menu,
  Empty,
} from "antd";
import { useNavigate } from "react-router-dom";
import PolicyCard from "../Policies/PolicyCard";
import DashboardCarousel from "./DashboardCarousel";
import PendingLoansHeader from "./PendingLoansHeader";
import PendingLoansCarousel from "./PendingLoansCarousel";
import RecommendedForYou from "./RecommendedForYou";
import products from "../../utils/data/products";
import DepositCard from "../Policies/DepositCard";
import useWindowSize from "../../utils/helper/useWindowSize";
import {
  DashboardContext,
  useDashboard,
} from "../../store/context/DashboardContext";
import { useTranslation } from "react-i18next";
import { useFeatures } from "../../store/context/feature-context";
import { useState } from "react";
import { featuresEnum } from "../../shared/constants/constants";
import { postAuthenticatedRequest } from "../../utils/api/requests/request";
import { getSessionData } from "../../utils/interceptors/localStorageService";
import { useNotification } from "../../store/context/notification-context";
import { usePermissions } from "../../store/context/permission-context";

const Dashboard = () => {
  const navigate = useNavigate();
  const isMobile = useWindowSize();
  const {
    selectedFilter,
    setSelectedFilter,
    savedSessionData,
    policies,
    getUserData,
    fetchPolicies,
    started,
    loading,
    error,
  } = useDashboard();
  const triggerNotification = useNotification();
  const { permissions } = usePermissions();

  const { t } = useTranslation();

  const { findFeature } = useFeatures();

  console.log("permissions from context-dashboard", permissions);

  useEffect(() => {
    const sessionData = getSessionData();
    console.log("sessiondata from dashboard is ", sessionData);
    if (!sessionData) {
      navigate("/authentication?mode=signin");
    } else if (!sessionData?.mobileNumber) {
      getUserData();
    }
  }, [triggerNotification, navigate, getUserData]);

  const handleFilterChange = (filter) => setSelectedFilter(filter);

  const handlePolicyClick = (policyNumber) =>
    navigate(`/policies/${policyNumber}`);
  // console.log("product ", products);
  const favProducts = products
    .filter((product) => product.category === "loans")
    .slice(0, 3);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let allCategories = [
      { key: "deposits", label: "Deposits" },
      { key: "loans", label: "Loans" },
      { key: "investments", label: "Investments" },
      { key: "insurance", label: "Insurance" },
    ];
    const categoriesFilterd = allCategories.filter((category) => {
      if (category.key === "loans") {
        return findFeature(featuresEnum.CUSTOMER_LOAN);
      }
      if (category.key === "insurance") {
        return findFeature(featuresEnum.CUSTOMER_INSURANCE);
      }
      return true; // Keep other categories by default
    });
    setCategories(categoriesFilterd);

    console.log(categoriesFilterd);
    // Use `filteredCategories` as needed
  }, [findFeature]);

  const handleViewProducts = () => {
    navigate("/products", { state: { default: "all" } });
  };

  const handlePinSetUp = () => {
    navigate("/settings/security/setup-pin");
  };

  if (policies.length === 0 && loading && !started) {
    fetchPolicies();
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    console.error(error);
    return <div>An error occurred. Please try again.</div>;
  }

  // if (!policies || policies.length === 0) {
  //   return <Empty description="No policies found" />;
  // }

  const sortedPolicies = policies
    .slice()
    .sort((a, b) => a.type.localeCompare(b.type));
  const filteredPolicies = sortedPolicies.filter((policy) =>
    policy.type.includes(selectedFilter)
  );
  // console.log("filteredPolicies ", filteredPolicies);
  // console.log("sortedPolicies ", sortedPolicies);
  return (
    <div className="p-4 pt-0 mb-10">
      {/* Header */}
      <div className="h-[52px] pt-[6px]">
        <div className="h-[52px]">
          <div className="h-[28px] text-[18px] text-[#615D5D]">
            Good Morning,{" "}
            <span className="font-bold text-[#221F1F]">
              {savedSessionData?.firstName}
            </span>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <Row gutter={16}>
          <div
            className="shadow-md hover:shadow-lg border border-gray-300 rounded-lg w-[335px] h-[266px] cursor-pointer mb-4 ml-2"
            onClick={handleViewProducts}
          >
            <div className="flex flex-col items-center justify-center w-full transition-shadow duration-300">
              <img
                src={"/dashboard.svg"}
                className="object-contain mb-6"
                alt="Insurance Modal"
              />
              <div className="w-full pl-3">
                <h4 className="text-[14px] font-semibold">
                  {t("Need a new account?")}
                </h4>
                <p className="text-[12px] mb-2 pt-2">
                  {t(
                    "Open an account with us to Save, invest, borrow, or protect your future"
                  )}
                </p>
              </div>
            </div>
          </div>

          {!(savedSessionData?.isPinSet ?? true) && (
            <div
              className="shadow-md hover:shadow-lg border border-gray-300 rounded-lg w-[335px] h-[266px] cursor-pointer mb-4 ml-4"
              onClick={handlePinSetUp}
            >
              <div className="flex flex-col items-center justify-center w-full transition-shadow duration-300">
                <img
                  src={"/pin.svg"}
                  className="object-contain mb-6"
                  alt="Setup PIN"
                />
                <div className="w-full pl-3">
                  <h4 className="text-[14px] font-semibold">
                    Setup Transaction PIN
                  </h4>
                  <p className="text-[12px] mb-2 pt-2">
                    Without your transaction pin, you would not be able to carry
                    out any transactions.
                  </p>
                </div>
              </div>
            </div>
          )}
        </Row>
      </div>

      <PendingLoansHeader />
      <PendingLoansCarousel />

      <div className="mt-10 mb-10">
        <div className="mb-2">
          <p className="mb-5 text-lg font-semibold text-left">
            {t("My Accounts")}
          </p>
        </div>
        <div className="mb-7">
          <Row gutter={16} className="items-center">
            {isMobile ? (
              <Col span={24}>
                <Dropdown
                  overlay={
                    <Menu>
                      {(categories ?? []).map((category) => (
                        <Menu.Item
                          key={category.key}
                          onClick={() => handleFilterChange(category.key)}
                        >
                          {category.label}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={["click"]}
                  className="w-full"
                >
                  <Button type="default" className="w-full">
                    {categories.find((cat) => cat.key === selectedFilter)
                      ?.label || "Select Category"}
                  </Button>
                </Dropdown>
              </Col>
            ) : (
              categories.map((category) => (
                <Col key={category.key}>
                  <Button
                    className={
                      selectedFilter === category.key
                        ? "text-[#e5791b] border-[#e5791b] font-semibold border-2 rounded"
                        : "border-2 font-semibold rounded border-gray-300"
                    }
                    onClick={() => handleFilterChange(category.key)}
                  >
                    {category.label}
                  </Button>
                </Col>
              ))
            )}
          </Row>
        </div>
      </div>

      <div className="relative ml-[-6px]">
        {filteredPolicies.length > 0 ? (
          <DashboardCarousel
            slides={filteredPolicies.map((policy) =>
              policy.type === "deposits" || policy.type === "loans" ? (
                <DepositCard
                  key={policy.policyNumber}
                  policy={policy}
                  onClick={() => handlePolicyClick(policy.policyNumber)}
                />
              ) : (
                <PolicyCard
                  key={policy.policyNumber}
                  policy={policy}
                  onClick={() => handlePolicyClick(policy.policyNumber)}
                />
              )
            )}
          />
        ) : (
          <Empty description={`No ${selectedFilter} available`} />
        )}
      </div>

      {findFeature(featuresEnum.CUSTOMER_LOAN) && favProducts.length > 0 && (
        <div className="mb-10">
          <div className="mb-2">
            <p className="mb-5 text-lg font-semibold text-left">
              {t("Recommended for you")}
            </p>
            <div className="text-[14px] font-sm">
              {t("We thought this might interest you")}
            </div>
          </div>
          <RecommendedForYou favProducts={favProducts} />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
