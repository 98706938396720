import { contentQuotesLinter } from "@ant-design/cssinjs/lib/linters";

export const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
  // console.log("parts. ",parts);
    // Assuming the domain has at least three parts (e.g., x.y.v)
    if (parts.length >= 3) {
      return parts[0]; // x in x.y.v
    }
    return null;
  };
  

  export const getDomain = () => {
    const hostname = window.location.hostname; // Gets hostname only
    return hostname; // Returns full domain like "example.com" or "sub.example.com"
  };
  