import { useState } from "react";
import { Form, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import ProfileInfo from "./signup-steps/ProfileInfo";
import ContactInfo from "./signup-steps/ContactInfo";
import ContactVerify from "./signup-steps/ContactVerify";
import SecurityInfo from "./signup-steps/SecurityInfo";
import { useNavigate } from "react-router-dom";
import { useApiRequests } from "../../utils/api/https/executor";
import { useFormData } from "../../store/context/form-data-context";
import { useNotification } from "../../store/context/notification-context";
import CompanyInfo from "./signup-steps/CompanyInfo";
import { useTenant } from "../../store/context/tenant-context";
import { navigateToAuth } from "../../utils/helper/redirect";

const Signup = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [form5] = Form.useForm();
  const {
    preRegisterUser,
    completeRegistration,
    preRegisterCorporateUser,
    // other functions...
  } = useApiRequests();
  const { formData: signUpContext, setFormData: setSignupContext } =
    useFormData();

  const navigate = useNavigate();
  const triggerNotification = useNotification();
  const tenant = useTenant();
  const forms = [form1, form2, form3, form4, form5];

  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: null,
    phoneArea: "+234",
    country: "Kenya",
    phoneNo: "",
    email: "",
    password: "",
    confirmPassword: "",
    selectedQuestions: [
      { question: "", answer: "" },
      { question: "", answer: "" },
      { question: "", answer: "" },
    ],
    userType: localStorage.getItem("accountType"),
  });

  const handleNavigate = () => {
    if (current === 0) navigateToAuth({ isAdmin: false });
    else if (current > 0 && current < contents.length - 1) {
      setCurrent(current - 1);
    }
  };

  const handleNext = async () => {
    try {
      await forms[current].validateFields();
      const isPersonal = formData.userType === "Personal";
      const isCorporate = formData.userType === "Corporate";

      if (current === 1 && isPersonal) {
        setIsLoading(true);
        const response = await preRegisterUser({
          firstName: formData.firstName,
          lastName: formData.lastName,
          emailAddress: formData.email,
          mobileCountryCode: formData.phoneArea,
          mobileNumber: formData.phoneNo,
          dateOfBirth: formData.birthDate,
          gender: formData.gender,
          userType: formData.userType,
          biometricsId: formData.biometricsId,
          identityInformation: {
            idNumber: formData.idNumber,
            idType: formData.idType,
            issueDate: formData.issueDate,
            expiryDate: formData.expiryDate,
            base64Doc: formData.base64Doc,
            docExtension: formData.docExtension,
          },
          addressInformation: {
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            state: formData.state,
            postalCode: formData.postalCode,
            city: formData.city,
            country: formData.country,
          },
        });

        handleApiResponse(response);
      } else if (current === 2 && isCorporate) {
        setIsLoading(true);
        const response = await preRegisterCorporateUser({
          firstName: formData.firstName,
          lastName: formData.lastName,
          emailAddress: formData.email,
          mobileCountryCode: formData.phoneArea,
          mobileNumber: formData.phoneNo,
          dateOfBirth: formData.birthDate,
          gender: formData.gender,
          companyName: formData.companyName,
          companyRegistrationNumber: formData.companyRegistrationNumber,
          companyAddress: formData.companyAddress,
          companyTaxNumber: formData.companyTaxNumber,
          userType: formData.userType,
          biometricsId: formData.biometricsId,
          identityInformation: {
            idNumber: formData.idNumber,
            idType: formData.idType,
            issueDate: formData.issueDate,
            expiryDate: formData.expiryDate,
            base64Doc: formData.base64Doc,
            docExtension: formData.docExtension,
          },
          addressInformation: {
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            state: formData.state,
            postalCode: formData.postalCode,
            city: formData.city,
            country: formData.country,
          },
        });

        handleApiResponse(response);
      } else {
        setCurrent(current + 1);
      }
    } catch (error) {
      // triggerNotification({
      //   type: "error",
      //   message: "Error",
      //   description:
      //     "Could not fulfill request. If the problem persists, please try again later.",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  const handleApiResponse = (response) => {
    const result = response?.outData;

    if (result?.isSuccessful) {
      triggerNotification({
        type: "success",
        message: "Success",
        description: result?.message || "Pre-Registration successful",
      });
      setSignupContext(result.data);
      setCurrent(current + 1);
    } else {
      triggerNotification({
        type: "error",
        message: "Pre-Registration Error",
        description:
          result?.message || "An error occurred. Please try again later",
      });
    }
  };

  const handlePrev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = async () => {
    try {
      await Promise.all(forms.map((form) => form.validateFields()));
      setIsLoading(true);
      const response = await completeRegistration({
        requestId: signUpContext.requestId,
        password: formData.password,
        emailAddress: formData.email,
        securityQuestion1: formData.selectedQuestions[0].question,
        securityAnswer1: formData.selectedQuestions[0].answer,
        securityQuestion2: formData.selectedQuestions[1].question,
        securityAnswer2: formData.selectedQuestions[1].answer,
        securityQuestion3: formData.selectedQuestions[2].question,
        securityAnswer3: formData.selectedQuestions[2].answer,
      });

      const result = response?.outData;

      if (result?.isSuccessful) {
        triggerNotification({
          type: "success",
          message: "Success",
          description: result.message || "Registration successful",
        });

        navigateToAuth({ isAdmin: false });
      } else {
        triggerNotification({
          type: "error",
          message: "Registration Error",
          description:
            result?.message ||
            "Could not complete registration. Please try again later",
        });
      }
    } catch (error) {
      // triggerNotification({
      //   type: "error",
      //   message: "Signup Failed",
      //   description: error.toString(),
      // });
    } finally {
      setIsLoading(false);
    }
  };

  const contents = [
    {
      content: (
        <ProfileInfo
          form={form1}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    formData?.userType === "Corporate" && {
      content: (
        <CompanyInfo
          form={form5}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      content: (
        <ContactInfo
          form={form2}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
    {
      content: (
        <ContactVerify
          form={form3}
          current={current}
          setCurrent={setCurrent}
          formData={formData}
        />
      ),
    },
    {
      content: (
        <SecurityInfo
          form={form4}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
  ].filter(Boolean);

  return (
    <div className="p-4">
      <div className="mt-16">
        {current !== contents.length - 1 && (
          <span>
            <button
              className={`focus:outline-none hover:text-[${tenant.theme.colorPrimary}]`}
            >
              <LeftOutlined className="mr-6" onClick={handleNavigate} />
            </button>
          </span>
        )}
        <span className="text-lg font-semibold text-left">
          Register ({formData.userType})
        </span>
      </div>

      <div>
        <div className="steps-content">{contents[current].content}</div>
        {formData.userType === "Personal" && current !== 2 && (
          <div className="flex flex-col gap-1 md:flex-row">
            {current > 0 && current < contents.length - 1 && (
              <Button
                onClick={handlePrev}
                htmlType="button"
                className="h-full px-3 py-2 text-base text-center shadow-none md:mr-2"
              >
                Back
              </Button>
            )}
            {current < contents.length - 1 && (
              <Button
                type="primary"
                onClick={handleNext}
                className="h-full px-3 py-2 text-base text-center shadow-none"
                loading={isLoading}
              >
                Continue
              </Button>
            )}
            {current === contents.length - 1 && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
                className="h-full px-3 py-2 text-base text-center shadow-none"
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Register"}
              </Button>
            )}
          </div>
        )}
        {formData.userType === "Corporate" && current !== 3 && (
          <div className="flex flex-col gap-1 md:flex-row">
            {current > 0 && current < contents.length - 1 && (
              <Button
                onClick={handlePrev}
                className="h-full px-3 py-2 text-base text-center shadow-none md:mr-2"
              >
                Back
              </Button>
            )}
            {current < contents.length - 1 && (
              <Button
                type="primary"
                onClick={handleNext}
                className="h-full px-3 py-2 text-base text-center shadow-none"
                loading={isLoading}
              >
                Continue
              </Button>
            )}
            {current === contents.length - 1 && (
              <Button
                type="primary"
                onClick={handleSubmit}
                className="h-full px-3 py-2 text-base text-center shadow-none"
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Register"}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
