import { ConfigProvider, theme, App, notification } from "antd";
import { useTheme } from "./store/context/theme-context";
import { getSubdomain } from './utils/routes/baseURLRetriver'; // Adjust the path as necessary
import { OtpProvider } from "./store/context/otp-context";
import AppRoutes from "./shared/routes/AppRoutes";
import { FormDataProvider } from "./store/context/form-data-context";
import { NotificationProvider } from "./store/context/notification-context";
import { WebSocketProvider } from "./shared/utils/singalr";
import { DashboardProvider } from "./store/context/DashboardContext";
import { BrowserRouter } from "react-router-dom";
import { OrganisationProvider } from "./store/context/admin/organisation-context";
import { UserProvider } from "./store/context/admin/user-context";
import { ApiProvider } from "./utils/api/https/executor";
import { FeatureProvider } from "./store/context/feature-context";
import { TenantProvider } from "./store/context/tenant-context";
import { getTenant } from "./shared/config/tenantConfiguration";
import MandateProvider from "./store/context/admin/mandate-context";
import { TransferProvider } from "./store/context/admin/transfer-context";
import { useEffect } from "react";


const MyApp = () => {

  const { theme: selectedTheme } = useTheme();

  const lightTheme = selectedTheme === "light";
  const tenant = getTenant();
  document.title = tenant.title;

    useEffect(() => {
      const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
      link.rel = 'icon';
      link.href = tenant.logo;
      if (!link.parentNode) {
        document.head.appendChild(link);
      }
    }, []);
    

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: tenant.theme.colorPrimary,
        },
        algorithm: lightTheme ? theme.defaultAlgorithm : tenant.theme.algorithm,
      }}
    >
      <App>
        <TenantProvider tenant={tenant}>
          <ApiProvider>
            <NotificationProvider>
              <FormDataProvider>
                <OtpProvider>
                  <BrowserRouter>
                    <DashboardProvider>
                      <WebSocketProvider>
                        <TransferProvider>
                          <OrganisationProvider>
                            <UserProvider>
                              <MandateProvider>
                                <AppRoutes />
                              </MandateProvider>
                            </UserProvider>
                          </OrganisationProvider>
                        </TransferProvider>
                      </WebSocketProvider>
                    </DashboardProvider>
                  </BrowserRouter>
                </OtpProvider>
              </FormDataProvider>
            </NotificationProvider>
          </ApiProvider>
        </TenantProvider>
      </App>
    </ConfigProvider>
  );
};

export default MyApp;
